import {useEffect, useState} from "react";

type Subscriber<T> = (value: T) => void;

export function createObservable<T>(initialValue: T) {
    let value = initialValue;
    const subscribers: Set<Subscriber<T>> = new Set();

    return {
        get: () => value,
        set: (newValue: T) => {
            if (newValue !== value) {
                value = newValue;
                subscribers.forEach((callback) => callback(value));
            }
        },
        subscribe: (callback: Subscriber<T>) => {
            subscribers.add(callback);
        },
        unsubscribe: (callback: Subscriber<T>) => {
            subscribers.delete(callback);
        },
    };
}

export function useObservable<T>(
    observable: ReturnType<typeof createObservable<T>>
): T {
    const [state, setState] = useState<T>(observable.get());

    useEffect(() => {
        observable.subscribe(setState);
        return () => observable.unsubscribe(setState);
    }, [observable]);

    return state;
}

export type Observable<T> = ReturnType<typeof createObservable<T>>

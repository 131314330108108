import INetworkService from "../../../network/INetworkService";
import { getApiHost } from "../../../utils/network/common";
import {
    IterationAddRequest,
    IterationAddResponse,
    iterationAddResponseError,
    IterationDeleteRequest,
    IterationDeleteResponse,
    iterationDeleteResponseError,
    IterationFinishRequest,
    IterationFinishResponse,
    iterationFinishResponseError,
    IterationExploreResponse,
    IterationListRequest,
    IterationListResponse,
    iterationListResponseError,
    IterationExploreRequest,
    iterationExploreResponseError,
    IterationUpdateRequest,
    IterationUpdateResponse,
    iterationUpdateResponseError
} from "../entities/entities";
import IIterationApiProvider from "./IIterationApiProvider";


class IterationApiProvider implements IIterationApiProvider {

    // IIterationApiProvider

    addIteration(
        request: IterationAddRequest,
        onResult: (response: IterationAddResponse) => void
    ) {
        this._addIteration(request, onResult);
    }

    deleteIteration(
        iterationId: number,
        onResult: (response: IterationDeleteResponse) => void
    ) {
        this._deleteIteration(iterationId, onResult);
    }

    exploreIteration(
        iterationId: number,
        onResult: (response: IterationExploreResponse) => void
    ) {
        this._getIteration(iterationId, onResult);
    }

    finishIteration(
        iterationId: number,
        onResult: (response: IterationFinishResponse) => void
    ) {
        this._finishIteration(iterationId, onResult);
    }

    listIteration(
        sphereId: number,
        onResult: (response: IterationListResponse) => void
    ) {
        this._listIteration(sphereId, onResult);
    }

    updateIteration(
        request: IterationUpdateRequest,
        onResult: (response: IterationUpdateResponse) => void
    ) {
        this._updateIteration(request, onResult)
    }

    // Setup

    private networkService: INetworkService;

    constructor(networkService: INetworkService) {
        this.networkService = networkService;
    }

    // Internal

    private _addIteration = (
        request: IterationAddRequest,
        onResult: (response: IterationAddResponse) => void
    ) => {

        let requestBody = request;
        let bodyString = JSON.stringify(requestBody);
        let config: RequestInit = {
            method: "post",
            body: bodyString,
        };
        this.networkService
            .signedRequest<IterationListResponse>(
                getApiHost() + "/v1/iteration/add",
                config
            )
            .then((result) => {
                onResult(result);
            })
            .catch((err) => {
                onResult(iterationAddResponseError);
            });
    };

    private _deleteIteration = (
        iterationId: number,
        onResult: (response: IterationDeleteResponse) => void
    ) => {

        let requestBody: IterationDeleteRequest = {iteration_id: iterationId};
        let bodyString = JSON.stringify(requestBody);
        let config: RequestInit = {
            method: "post",
            body: bodyString,
        };
        this.networkService
            .signedRequest<IterationListResponse>(
                getApiHost() + "/v1/iteration/delete",
                config
            )
            .then((result) => {
                onResult(result);
            })
            .catch((err) => {
                onResult(iterationDeleteResponseError);
            });
    };

    private _getIteration = (
        iterationId: number,
        onResult: (response: IterationExploreResponse) => void
    ) => {

        let requestBody: IterationExploreRequest = {iteration_id: iterationId};
        let bodyString = JSON.stringify(requestBody);
        let config: RequestInit = {
            method: "post",
            body: bodyString,
        };
        this.networkService
            .signedRequest<IterationExploreResponse>(
                getApiHost() + "/v1/iteration/explore",
                config
            )
            .then((result) => {
                onResult(result);
            })
            .catch((err) => {
                onResult(iterationExploreResponseError);
            });
    };

    private _finishIteration = (
        iterationId: number,
        onResult: (response: IterationFinishResponse) => void
    ) => {

        let requestBody: IterationFinishRequest = {iteration_id: iterationId};
        let bodyString = JSON.stringify(requestBody);
        let config: RequestInit = {
            method: "post",
            body: bodyString,
        };
        this.networkService
            .signedRequest<IterationListResponse>(
                getApiHost() + "/v1/iteration/finish",
                config
            )
            .then((result) => {
                onResult(result);
            })
            .catch((err) => {
                onResult(iterationFinishResponseError);
            });
    };

    private _listIteration = (
        sphereId: number,
        onResult: (response: IterationListResponse) => void
    ) => {

        let requestBody: IterationListRequest = {sphere_id: sphereId};
        let bodyString = JSON.stringify(requestBody);
        let config: RequestInit = {
            method: "post",
            body: bodyString,
        };
        this.networkService
            .signedRequest<IterationListResponse>(
                getApiHost() + "/v1/iteration/list",
                config
            )
            .then((result) => {
                onResult(result);
            })
            .catch((err) => {
                onResult(iterationListResponseError);
            });
    };

    private _updateIteration = (
        request: IterationUpdateRequest,
        onResult: (response: IterationUpdateResponse) => void
    ) => {

        let requestBody = request;
        let bodyString = JSON.stringify(requestBody);
        let config: RequestInit = {
            method: "post",
            body: bodyString,
        };
        this.networkService
            .signedRequest<IterationUpdateResponse>(
                getApiHost() + "/v1/iteration/update",
                config
            )
            .then((result) => {
                onResult(result);
            })
            .catch((err) => {
                onResult(iterationUpdateResponseError);
            });
    };

}
export default IterationApiProvider;

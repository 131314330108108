import "./IterationAddForm.css";

import {useEffect, useRef, useState} from "react";

import {Button} from "@consta/uikit/Button";
import {Modal} from "@consta/uikit/Modal";
import {TextField, TextFieldPropValue} from "@consta/uikit/TextField";
import { Text } from "@consta/uikit/Text";

import FormHeader from "../../controls/formHeader/FormHeader";
import IIterationService from "../../../services/iteration/IIterationService";

function IterationAddForm(props: IIterationAddFormProps) {
    
    const [volume, setVolume] = useState<number>(0);
    const [caption, setCaption] = useState<string>("Укажите объем итерации");
    const [status, setStatus] = useState<"alert" | "success" | "warning" | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleVolume = (value: TextFieldPropValue) => {
        const numberValue = (value && +value) || 0
        setVolume(numberValue);
        if (numberValue <= 0) {
            setCaption("Значение должно быть больше нуля")
            setStatus("alert")
        } else {
            setCaption("Объем итерации")
            setStatus("success")
        }
    };

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        inputRef.current?.focus();
    }, [props.isModalOpen]);

    const clean = () => {
        setVolume(0);
    };

    const close = () => {
        props.onClose();
        clean();
    };

    const submit = () => {
        if (volume) {
            setIsLoading(true)
            const iterationService = props.iterationService
            iterationService.addIteration(volume, addResponse => {
                if (addResponse.success) {
                    iterationService.fetchIterationList((fetchListResponse) => {
                        if(fetchListResponse.success) {
                            iterationService.fetchActiveIteration(() => {
                                setIsLoading(false)
                                close();
                            })
                        } else {
                            close();
                        }
                    })
                } else {
                    const errorMessage = addResponse.error?.message || "Неизвестная ошибка"
                    setCaption(errorMessage)
                    setStatus("alert")
                    setIsLoading(false)
                }
            })
        }
    }

    return (
        <Modal
            isOpen={props.isModalOpen}
            hasOverlay
            position="center"
            onClickOutside={() => close()}
            onEsc={() => {
                close();
            }}
        >
            <div className="IterationAddForm">
                <FormHeader onClose={close} text="Новая итерация" />
                <div className="IterationAddForm-content">
                    <TextField
                        inputRef={inputRef}
                        value={volume?.toString()}
                        type="number"
                        placeholder="Укажите объем новой итерации"
                        onChange={handleVolume}
                        caption={caption}
                        status={status}
                    />
                    <Text className="Description" view="brand">Итерация — это количество единиц ресурса, которые необходимо распределить между направлениями.
                    </Text>
                    <Text className="Description" view="primary">Единица ресурса — это безразмерная величина, поскольку для задачи распределения учитывается только доля от общего объема.
                    </Text>
                    <Text className="Description" view="primary">Допустим, в следующем месяце вы верите, что у вас будет 160 свободных часов. Значит, в поле ввода нужно указать число 160. Если вы измеряете ресурсы «помидорках», то укажите другое подходящее число, например, 200.
                    </Text>
                    <Text className="Description" view="primary" weight="semibold">Важно: итерация не привязана к какому-либо плану или временному отрезку! Итерация формируется исключительно как объем распределяемых ресурсов!
                    </Text>
                </div>

                <div className="IterationAddForm-footer">
                    <Button
                        size="m"
                        view="primary"
                        label="Готово"
                        width="default"
                        onClick={submit}
                        disabled={!(volume) || volume < 0}
                        loading={isLoading}
                    />
                </div>
            </div>
        </Modal>
    );
}

type IIterationAddFormProps = {
    isModalOpen: boolean;
    iterationService: IIterationService;
    onClose: VoidFunction;
};

export default IterationAddForm;

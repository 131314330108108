import {Node} from "../entities/Node";

export enum SphereMode {
    Source = 1,
    Projection
}

export type SphereItem = {
    sphere_id: number;
    title: string;
    create_time: string;
    update_time: string;
};

export type SphereContent = {
    nodes: Node[];
    lastNodeAutoIncrement?: string;
    lastLinkAutoIncrement?: string;
};

export const startNodeIdValue = 100

export const emptySphereContent = () => {
    return { nodes: [] }
}

export type SphereDto = {
    sphere_id: number;
    title: string;
    content: string;
};

function classOf(obj: unknown) : string {
    if (obj === null) return "Null";
    if (typeof obj === "undefined") return "Undefined";

    if (typeof obj === 'object') {
        // Warning: this won't work if your Typescript is minified and class names are mangled.
        return (obj as object).constructor.name;
    }

    if (typeof obj === 'function') {
        // Warning: this won't work if your Typescript is minified and function names are mangled.
        const possibleName = (obj as CallableFunction).name;
        if (possibleName) {
            return possibleName;
        }
    }

    return Object.prototype.toString.call(obj).slice(8, -1);
}

export function parseSphereContent(content: string| null): SphereContent {
    let sphereContent: SphereContent = emptySphereContent()
    if (!content) {
        return sphereContent
    }
    const json = JSON.parse(content)
    // миграция: старые сферы содержат массив узлов, новые содержат узлы
    // в отдельном поле
    if (classOf(json) === "Object") {
        sphereContent = json as SphereContent;
    } else if (classOf(json) === "Array") {
        sphereContent.nodes = json as Node[] || [];
    }
    return sphereContent
}
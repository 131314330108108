import "./App.css";
import "./Content.css";

import {useState} from "react";

import StartScreen from "../screens/start/StartScreen";
import SphereScreen from "../screens/sphere/SphereScreen";
import DashboardScreen from "../screens/dashboard/DashboardScreen";

import ICore from "../../services/core/ICore";
import Core from "../../services/core/Core";

import Router from "../router/Router";

import IStorage from "../../services/storage/IStorage";
import Storage from "../../services/storage/Storage";
import IAuthService from "../../services/auth/IAuthService";
import AuthService from "../../services/auth/AuthService";
import IAuthServiceSetup from "../../services/auth/IAuthServiceSetup";
import ISphereService from "../../services/sphere/ISphereService";
import SphereService from "../../services/sphere/SphereService";
import ISphereApiProvider from "../../services/api/sphere/provider/ISphereApiProvider";
import SphereApiProvider from "../../services/api/sphere/provider/SphereApiProvider";
import IAuthApiProvider from "../../services/api/auth/provider/IAuthApiProvider";
import AuthApiProvider from "../../services/api/auth/provider/AuthApiProvider";
import INetworkService from "../../services/network/INetworkService";
import NetworkService from "../../services/network/NetworkService";
import {AppScreen, Route} from "../router/routes";
import ContentScreen from "../screens/content/ContentScreen";
import IRouter from "../router/IRouter";
import IRouterSetup from "../router/IRouterSetup";
import ContactsPage from "../content/contacts/ContactsPage";
import ManualPage from "../content/manual/ManualPage";
import TariffsPage from "../content/tariffs/TariffsPage";
import TermsPage from "../content/terms/TermsPage";
import PrivacyPage from "../content/privacy/PrivacyPage";
import PaymentHistoryScreen from "../screens/paymentHistory/PaymentHistoryScreen";

import IPaymentService from "../../services/payment/IPaymentService";
import PaymentService from "../../services/payment/PaymentService";
import IPaymentApiProvider from "../../services/api/payment/provider/IPaymentApiProvider";
import PaymentApiProvider from "../../services/api/payment/provider/PaymentApiProvider";

import ITariffService from "../../services/tariff/ITariffService";
import TariffService from "../../services/tariff/TariffService";
import ITariffApiProvider from "../../services/api/tariff/provider/ITariffApiProvider";
import TariffApiProvider from "../../services/api/tariff/provider/TariffApiProvider";
import ProductApiProvider from "../../services/api/product/provider/ProductApiProvider";
import IProductApiProvider from "../../services/api/product/provider/IProductApiProvider";
import ProductService from "../../services/product/ProductService";
import IProductService from "../../services/product/IProductService";
import IIterationService from "../../services/iteration/IIterationService";
import IterationService from "../../services/iteration/IterationService";
import IIterationApiProvider from "../../services/api/iteration/provider/IIterationApiProvider";
import IterationApiProvider from "../../services/api/iteration/provider/IterationApiProvider";

// Create services

const storage: IStorage = new Storage();
const core: ICore = new Core(storage);
const router: IRouter & IRouterSetup = new Router();
const authApiProvider: IAuthApiProvider = new AuthApiProvider();
const authService: IAuthService & IAuthServiceSetup = new AuthService(
    authApiProvider,
    storage
);
const networkService: INetworkService = new NetworkService(authService);
const sphereApiProvider: ISphereApiProvider = new SphereApiProvider(
    networkService
);
const paymentApiProvider: IPaymentApiProvider = new PaymentApiProvider(
    networkService
);
const tariffApiProvider: ITariffApiProvider = new TariffApiProvider(
    networkService
);
const productApiProvider: IProductApiProvider = new ProductApiProvider(
    networkService
);
const iterationApiProvider: IIterationApiProvider = new IterationApiProvider(
    networkService
);

const iterationService: IIterationService = new IterationService(
    iterationApiProvider,
    core,
    storage
);

const paymentService: IPaymentService = new PaymentService(paymentApiProvider);
const sphereService: ISphereService = new SphereService(sphereApiProvider);
const tariffService: ITariffService = new TariffService(tariffApiProvider);
const productService: IProductService = new ProductService(productApiProvider);

// Connect services

router.isAuth = () => {
    return authService.isSignedIn();
};

// Activate services

router.reload();

// Launch UI

function App() {

    const [page, setPage] = useState<AppScreen>(router.getCurrentPage());
    const [isLoading, setIsLoading] = useState<Boolean>(false);

    authService.onReload = () => {
        router.reload();
    };

    router.onReload = () => {
        setPage(router.getCurrentPage());
        setIsLoading(router.getIsLoading())
    };

    const renderContentPage = () => {
        switch (router.getCurrentRoute()) {
            case Route.ContentContacts:
                var title = "Контакты";
                var content = <ContactsPage />;
                break;
            case Route.ContentManual:
                var title = "Инструкция";
                var content = <ManualPage />;
                break;
            case Route.ContentPrivacy:
                var title = "Политика конфиденциальности";
                var content = <PrivacyPage />;
                break;
            case Route.ContentTariffs:
                var title = "Тарифные планы";
                var content = (
                    <TariffsPage
                        authService={authService}
                        tariffService={tariffService}
                        router={router}
                        storage={storage}
                        productService={productService}
                        paymentService={paymentService}
                    />
                );
                break;
            case Route.ContentTermOfUse:
                var title =
                    "Соглашение об общих условиях регистрации и использования сервисов";
                var content = <TermsPage />;
                break;
            default:
                var title = "Страница не найдена";
                var content = <></>;
                break;
        }
        return (
            <ContentScreen
                title={title}
                authService={authService}
                router={router}
            >
                {content}
            </ContentScreen>
        );
    };

    const renderPage = () => {
        switch (page) {
            case AppScreen.Dashboard:
                return (
                    <DashboardScreen
                        authService={authService}
                        router={router}
                        storage={storage}
                        sphereService={sphereService}
                        iterationService={iterationService}
                    />
                );
            case AppScreen.Content:
                return (
                    <div className="Content-page">
                        {renderContentPage()}
                    </div>
                );
            case AppScreen.Start:
                return (
                    <StartScreen
                        onContinue={() => setPage(AppScreen.Sphere)}
                        router={router}
                        storage={storage}
                    />
                );
            case AppScreen.Sphere:
                return (
                    <SphereScreen
                        authService={authService}
                        core={core}
                        storage={storage}
                        sphereService={sphereService}
                        router={router}
                        isLoading={isLoading}
                        iterationService={iterationService}
                    />
                );
            case AppScreen.PaymentHistory:
                return (
                    <PaymentHistoryScreen
                        authService={authService}
                        router={router}
                        paymentsService={paymentService}
                    />
                );
        }
    };

    return (
        <div className="App">
            <div className="App-content">{renderPage()}</div>
        </div>
    );
}

export default App;

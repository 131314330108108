import { IconAdd } from "@consta/icons/IconAdd";
import { Button } from "@consta/uikit/Button";
import { Tooltip } from "@consta/uikit/Tooltip";
import { Text } from "@consta/uikit/Text";
import {useRef, useState} from "react";
import { IconComponent } from "@consta/icons/Icon";

function HintedButton(props: IHintedButtonProps) {
    
    const btnRef = useRef<HTMLButtonElement>(null);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    return (
        <div>
            <Button
                className="sphere-header-button"
                size="s"
                view="primary"
                width="default"
                onlyIcon
                iconRight={props.icon}
                ref={btnRef}
                onMouseEnter={() =>
                    setIsTooltipVisible(true)
                }
                onMouseLeave={() =>
                    setIsTooltipVisible(
                        false
                    )
                }
                onClick={props.onClick}
            />
            <Tooltip
                isOpen={isTooltipVisible}
                anchorRef={btnRef}
                placeholder={""}
            >
                <Text view="primary" lineHeight="m" size="xs">
                    {props.hint}
                </Text>
            </Tooltip>
        </div>
    ) 
        
    
}

type IHintedButtonProps = {
    icon: IconComponent;
    hint: string;
    onClick: VoidFunction;
};

export default HintedButton;
import "./EditLinkForm.css";

import {useEffect, useRef, useState} from "react";

import {Node} from "../../../entities/Node";
import {NodeType} from "../../../entities/NodeType";
import {TempLink} from "../../../entities/Link";

import {Combobox} from "@consta/uikit/Combobox";
import {TextField} from "@consta/uikit/TextField";
import {Modal} from "@consta/uikit/Modal";
import {Button} from "@consta/uikit/Button";

type Item = {
    id: number;
    groupId: number;
    nodeId: string;
    label: string;
};

let counter = 1;
function nodeToItem(node: Node): Item {
    return {
        id: counter++,
        groupId: getGroupId(node.type),
        nodeId: node.id,
        label: node.title,
    };
}

function getGroupId(nodeType: NodeType): number {
    switch (nodeType) {
        case NodeType.Task:
            return 1;
        case NodeType.Group:
            return 2;
        case NodeType.Domain:
            return 3;
        case NodeType.Key:
            return 4;
    }
}

type Group = {
    label: string;
    id: number;
};

const groups: Group[] = [
    // задачи не линкуются
    // {
    //     id: 1,
    //     label: "Задачи",
    // },
    {
        id: 3,
        label: "Домены-листья",
    },
    {
        id: 2,
        label: "Направления-листья",
    },
    {
        id: 4,
        label: "Ключ",
    },
];

function EditLinkForm(props: IEditLinkForm) {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        inputRef.current?.focus();
    }, [props.isModalOpen]);

    const close = () => {
        props.onClose();
    };

    const onSelect = (item: Item | null) => {
        setValue(item);
    };

    const [value, setValue] = useState<Item | null>(null);

    const [description, setDescription] = useState<string | null>(null);

    useEffect(() => {
        const selectedItem =
            props.selectedNode && nodeToItem(props.selectedNode);
        setValue(selectedItem);
    }, [props.selectedNode]);

    const handleChange = (value : string | null) => {
        setDescription(value);

    }

    useEffect(() => {
        setDescription(props.description || null)
    }, [props.selectedNode]);

    return (
        <Modal
            className="EditLinkForm"
            isOpen={props.isModalOpen}
            hasOverlay
            position="center"
            onClickOutside={() => close()}
            onEsc={() => close()}
        >
            <div className="EditLinkForm">
                <div className="EditLinkFormContent">
                    <Combobox
                        inputRef={inputRef}
                        items={props.nodes.map(nodeToItem)}
                        groups={groups}
                        value={value}
                        onChange={onSelect}
                        placeholder="На что узел влияет?"
                        labelForEmptyItems="Нет доступных узлов"
                        virtualScroll
                        dropdownOpen={props.isInitial ? true : false}
                        disabled={props.nodes.length === 0}
                    />
                    <TextField
                        type="textarea"
                        cols={200}
                        rows={3}
                        placeholder="Обоснование связи"
                        value={description}
                        onChange={handleChange}
                    />
                    <div className="EditLinkFormContentButtonContainer">
                        <Button
                            label="Отмена"
                            view="secondary"
                            onClick={() => close()}
                        ></Button>
                        <Button
                            label="Готово"
                            view="primary"
                            disabled={value == null}
                            onClick={() => {
                                value && props.onSelect({targetNodeId: value.nodeId, linkDescription: description || undefined});
                                close()
                            }}
                        ></Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

type IEditLinkForm = {
    isModalOpen: boolean;
    isInitial: Boolean;
    selectedNode: Node | null;
    description?: string | null;
    nodes: Node[];
    onClose: VoidFunction;
    onSelect: (link: TempLink) => void;
};

export default EditLinkForm;

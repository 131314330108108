import "./CountingView.css";

import {ChoiceGroup} from "@consta/uikit/ChoiceGroup";
import {useEffect, useState} from "react";
import IterationTable from "../iterationTable/IterationTable";
import IIterationService from "../../../services/iteration/IIterationService";
import LoaderView from "../loaderView/LoaderView";
import { Node } from "../../../entities/Node";
import TaskPriorityTable from "../taskPriorityTable/TaskPriorityTable";
import ChargeTable from "../chargeTable/ChargeTable";
import BalanceView from "../balanceView/BalanceView";
import { NodeType } from "../../../entities/NodeType";

type Item = string;

export enum CountingViewTab {
    ResCounting = "Учёт",
    TaskQueue = "Очередь",
    Balance = "Баланс",
}

function CountingView(props: ICountingViewProps) {
    
    const tabItems: Item[] = [
        CountingViewTab.TaskQueue,
        CountingViewTab.ResCounting,
        CountingViewTab.Balance,
    ];

    const [tabValue, setTabValue] = useState<Item | null>(CountingViewTab.TaskQueue);
    const [isLoading, setIsLoading] = useState<Boolean>(false);

    useEffect(() => {
        setIsLoading(true)
        let iterationService = props.iterationService
        iterationService.fetchIterationList(() => {
            iterationService.fetchActiveIteration(() => {
                iterationService.reloadIterationWeights(() => {
                    setIsLoading(false);
                })
            })
        })
    }, [])

    const shouldShowIterationTable = () => {
        if (isLoading) {
            return false
        }
        if (!props.iterationService.getCurrentIterationId()) {
            return true
        }
        return false
    }
    const shouldShowIterationEditor = () => {
        if (isLoading) {
            return false
        }
        return !shouldShowIterationTable()
    }

    const deleteIteration = (iterationId: number) => {
        const iterationService = props.iterationService
        iterationService.deleteIteration(iterationId, () => {})
    }
    
    const selectIteration = (iterationId: number) => {
        const iterationService = props.iterationService
        iterationService.exploreIteration(iterationId, response => {
            if (response.success && response.iteration) {
                iterationService.setCurrentIteration(response.iteration || null)
                iterationService.reloadIterationWeights(() => {})
            }
        })
    }

    const getChargedTaskList = () => {
        const iteration = props.iterationService.currentIteration$.get()
        if (iteration) {
            const nodes = iteration.node_snapshots.nodes
            if (nodes) {
                return nodes.filter((node) => node.type === NodeType.Task)
            }
        }
        return []
    }
    

    return (
        <div className="CountingView">
            { isLoading && (
                <LoaderView />
            )}
            {shouldShowIterationTable() && (
                <IterationTable
                    iterationService={props.iterationService}
                    onDeleteIteration={deleteIteration}
                    onSelectIteration={selectIteration}
                />
            )}
            {shouldShowIterationEditor() && (
                <>
                    <ChoiceGroup
                        className="TabContainer"
                        value={tabValue}
                        onChange={setTabValue}
                        items={tabItems}
                        getItemLabel={(item) => item}
                        multiple={false}
                        name="ChoiceGroupExample"
                    />
                    {tabValue === CountingViewTab.Balance &&(
                        <BalanceView
                        leafGroupNodes={props.leafGroupNodes}    
                        iterationService={props.iterationService}
                        />)
                    }
                    {tabValue === CountingViewTab.TaskQueue && (
                        <TaskPriorityTable
                            taskNodes={props.taskNodes}
                            iterationService={props.iterationService}
                            onChangeTaskVisibility={props.onChangeTaskVisibility}
                            onDeleteNode= {props.onDeleteNode}
                            onEditNode={props.onEditNode}
                        />
                    )}
                    {tabValue === CountingViewTab.ResCounting && (
                        <ChargeTable
                            iterationService={props.iterationService}
                            taskNodes={getChargedTaskList()}
                        />
                    )}
                </>
            )}
        </div>
    );
}

type ICountingViewProps = {
    taskNodes: Node[];
    leafGroupNodes: Node[];
    iterationService: IIterationService;
    onChangeTaskVisibility: (nodeId: string) => void;
    onDeleteNode: (nodeId: string) => void;
    onEditNode: (nodeId: string) => void;
};

export default CountingView;

import { ApiCharge, Charge, chargeToApi } from "./Charge";
import { Node } from "../entities/Node";
import { ApiNode } from "../services/api/sphere/sphere";

export type Iteration = {
    iteration_id: number;
    is_finished: boolean;
    volume: number;
    activity: IterationActivity;
    result: IterationResult;
    node_snapshots: IterationNodeSnapshots;
};

export type IterationItem = {
    iteration_id: number;
    is_finished: boolean;
    create_time: string;
    update_time: string;
};

export type IterationDto = {
    iteration_id: number;
    is_finished: boolean;
    volume: number;
    activity: string;
    result: string;
    node_snapshots: string;
};

export type IterationActivity = {
    charges: Charge[];
    lastChargeAutoIncrement?: string;
};

export type IterationResult = {
};

export type IterationNodeSnapshots = {
    nodes: Node[];
};

export const startChargeIdValue = 100

export const emptyIterationActivity = () => {
    return { charges: [] }
}

export const emptyIterationResult = () => {
    return {}
}

export const emptyIterationNodeSnapshots = () => {
    return { nodes: [] }
}

export function parseIterationActivity(content: string| null): IterationActivity {
    let iterationActivity: IterationActivity = emptyIterationActivity()
    if (!content) {
        return iterationActivity
    }
    const json = JSON.parse(content)
    return json as IterationActivity;
}

export function parseIterationResult(content: string| null): IterationResult {
    let iterationResult: IterationResult = emptyIterationResult()
    if (!content) {
        return iterationResult
    }
    const json = JSON.parse(content)
    return json as IterationResult;
}

export function parseIterationNodeSnapshots(content: string| null): IterationNodeSnapshots {
    let iterationNodeSnapshots: IterationNodeSnapshots = emptyIterationNodeSnapshots()
    if (!content) {
        return iterationNodeSnapshots
    }
    const json = JSON.parse(content)
    return json as IterationNodeSnapshots;
}


export type ApiIteration = {
    snapshot_tasks: ApiNode[];
    volume: number;
    charges: ApiCharge[];
}

import { Iteration, IterationDto, IterationItem } from "../../../../entities/Iteration";
import { CommonError } from "../../../utils/errors/error";
import {connectionError} from "../../../utils/network/errors";


// IterationAdd

export type IterationAddRequest = {
    sphere_id: number,
    volume: number;
};

export type IterationAddResponse = {
    success: boolean;
    error?: CommonError;
};

export const iterationAddResponseError: IterationAddResponse = {
    success: false,
};


// IterationDelete

export type IterationDeleteRequest = {
    iteration_id: number;
};

export type IterationDeleteResponse = {
    success: boolean;
    error?: CommonError;
};

export const iterationDeleteResponseError: IterationDeleteResponse = {
    success: false,
};

// IterationExplore

export type IterationExploreRequest = {
    iteration_id: number;
};

export type IterationExploreResponse = {
    success: boolean;
    error?: CommonError;
    iteration?: IterationDto;
};

export type IterationExploreParsedResponse = {
    success: boolean;
    error?: CommonError;
    iteration?: Iteration;
};

export const iterationExploreResponseError: IterationExploreResponse = {
    success: false,
};

// IterationFinish

export type IterationFinishRequest = {
    iteration_id: number;
};

export type IterationFinishResponse = {
    success: boolean;
    error?: CommonError;
};

export const iterationFinishResponseError: IterationFinishResponse = {
    success: false,
};


// IterationList

export type IterationListRequest = {
    sphere_id: number;
};

export type IterationListResponse = {
    success: boolean;
    error?: CommonError;
    list: IterationItem[];
};

export const iterationListResponseError: IterationListResponse = {
    success: false,
    error: connectionError,
    list: [],
};


// IterationAdd

export type IterationUpdateRequest = {
    iteration_id: number,
    volume: number;
    is_finished: boolean;
    activity: string;
    result: string;
    node_snapshots: string;
};

export type IterationUpdateResponse = {
    success: boolean;
    error?: CommonError;
};

export const iterationUpdateResponseError: IterationUpdateResponse = {
    success: false,
};

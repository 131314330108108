import {useRef, useState} from "react";
import "./TaskPriorityTable.css";

import {Button} from "@consta/uikit/Button";

import {ContextMenu} from "@consta/uikit/ContextMenu";
import {
    CellClickType,
    SortByProps,
    Table,
    TableColumn,
} from "@consta/uikit/Table";
import {IconComponent} from "@consta/icons/Icon";
import {IconEdit} from "@consta/icons/IconEdit";
import {IconKebab} from "@consta/icons/IconKebab";
import {IconCalculator} from "@consta/icons/IconCalculator";
import {IconTrash} from "@consta/icons/IconTrash";

import {NodeType, nodeTypeToString} from "../../../entities/NodeType";
import {
    Node,
    maxQuality,
    nodeImpactString,
} from "../../../entities/Node";
import EmptySphere from "../emptySphere/EmptySphere";
import ChargeAddForm from "../../forms/chargeAddForm/ChargeAddForm";
import IIterationService from "../../../services/iteration/IIterationService";
import { useObservable } from "../../../utils/Observable";
import { PriorityWeight } from "../../../entities/PriorityWeight";
import { Switch } from "@consta/uikit/Switch";

type NodeRow = {
    id: string;
    priority: number;
    priority_str: string;
    title: string;
    links: number;
    type: string;
    visibility: boolean;
    node: Node;
};

// названия повторяют компоненты строки NodeRow

enum NodeTableColumns {
    Links = "links",
    Priority = "priority_str",
    Title = "title",
    Visibility = "visibility",
}

function nodeToRow(node: Node): NodeRow {
    let nodeType = nodeTypeToString(node.type);
    if (node.type === NodeType.Domain && node.quality) {
        let scaleFactor = 1.0 / (node.quality / maxQuality);
        nodeType = nodeType + ` (x${Math.round(scaleFactor * 100) / 100})`;
    }
    return {
        id: node.id,
        priority: node.impact,
        priority_str: nodeImpactString(node.impact),
        title: node.title,
        type: nodeTypeToString(node.type),
        links: node.links.length,
        visibility: true,
        node: node,
    };
}

function makeColumns(
    isActive: boolean,
    onChangeVisibility: (rowId: string) => void,
    getMenuRowId: () => string | undefined,
    getRef: () => React.RefObject<HTMLButtonElement>
): TableColumn<NodeRow>[] {
    let items: TableColumn<NodeRow>[] = [];
    
    items.push({
        title: "Приоритет",
        accessor: NodeTableColumns.Priority,
        align: "center",
        width: 140,
        sortable: true,
    });
    
    items.push({
        title: "Название",
        accessor: NodeTableColumns.Title,
        align: "left",
        sortable: true,
    });
    
    items.push({
        title: "Количество связей",
        accessor: NodeTableColumns.Links,
        align: "center",
        width: 160,
        sortable: true,
    });
    
    items.push({
        title: "Видимость на сфере",
        accessor: NodeTableColumns.Visibility,
        align: "center",
        sortable: true,
        width: 120,
        renderCell: (row) => {
            return (
                <div className="SwitchContainer" onClick={ event => event.stopPropagation()}>
                    <Switch
                        view="primary"
                        checked={!row.node.is_task_hidden}
                        onChange={(event) => {
                            onChangeVisibility(row.node.id)
                        }}
                    />
                </div>
            );
        },
    });
    
    if(isActive) {
        items.push({
            title: "",
            accessor: "id",
            align: "center",
            width: 60,
            renderCell: (row) => {
                return (
                    <Button
                        ref={row.id === getMenuRowId() ? getRef() : undefined}
                        size="s"
                        view="clear"
                        label={row.id}
                        onlyIcon
                        iconLeft={IconKebab}
                    />
                );
            },
        });
        }
    return items;
}

function TaskPriorityTable(props: ITaskPriorityTable) {
    const menuRef = useRef(null);
    const [menuRowId, setMenuRowId] = useState<string>("");
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

    const currentIteration = useObservable(props.iterationService.currentIteration$);
    const isActive = () => {
        return (currentIteration && !currentIteration.is_finished) || false
    }
    
    const priorityWeights = useObservable(props.iterationService.priorityWeightList$)

    const closeMenu = () => {
        setMenuRowId("");
        setMenuIsOpen(false);
    };

    const openMenu = (rowId: string) => {
        setMenuRowId(rowId);
        setMenuIsOpen(true);
    };

    const columns = makeColumns(
        isActive(),
        (nodeId) => {
            props.onChangeTaskVisibility(nodeId)
        },
        () => menuRowId,
        () => menuRef
    );

    const onCellClick = ({
        e,
        type,
        rowId,
        columnIdx,
        ref,
    }: {
        e: React.SyntheticEvent;
        type: CellClickType;
        columnIdx: number;
        ref: React.RefObject<HTMLDivElement>;
        rowId?: string;
    }) => {
        if (!isActive()) {
            return
        }
        const actionsIndex = columns.length - 1;
        const titleIndex = 1;
        if (columnIdx === actionsIndex) {
            rowId && openMenu(rowId);
        }
        if (columnIdx === titleIndex) {
            rowId && showAddResForm(rowId);
        }
    };

    type MenuItem = {
        label: string;
        imageRight?: IconComponent;
        status?: "primary" | "alert";
        onClick?: React.MouseEventHandler;
    };


    const [isAddResFormVisible, setAddResFormVisible] = useState<boolean>(false);
    const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);

    const showAddResForm = (nodeId: string) => {
        setSelectedNodeId(nodeId);
        setAddResFormVisible(true);
    }

    const items: MenuItem[] = [
        {
            label: "Начислить ресурсы",
            imageRight: IconCalculator,
            onClick: () => {
                showAddResForm(menuRowId);
                closeMenu();
            },
        },
        {
            label: "Редактировать",
            imageRight: IconEdit,
            onClick: () => {
                props.onEditNode(menuRowId);
                closeMenu();
            },
        },
        {
            label: "Удалить",
            imageRight: IconTrash,
            status: "alert",
            onClick: () => {
                props.onDeleteNode(menuRowId);
                closeMenu();
            },
        },
    ];

    const emptyTable = () => {
        return <EmptySphere />;
    };

    const [sortSetting, setSortSetting] =
        useState<SortByProps<NodeRow> | null>({sortingBy: NodeTableColumns.Priority, sortOrder : "desc" });

    const rows = (priorityWeights: PriorityWeight[]) => {

        let linkedNodes = props.taskNodes.filter(node => node.links.length > 0)

        // приоритет - это вес с учетом заполненности направлений в итерации
        let nodes = linkedNodes.map(node => {
            let priorityNode = { ...node }
            for (let weight of priorityWeights) {
                if (weight.node_id === priorityNode.id) {
                    priorityNode.impact = weight.impact
                    break
                }
            }
            return priorityNode
        })

        if (sortSetting?.sortingBy === NodeTableColumns.Priority) {
            if (sortSetting?.sortOrder === "asc") {
                return nodes
                    .map(nodeToRow)
                    .sort((a, b) => a.priority - b.priority);
            }
            if (sortSetting?.sortOrder === "desc") {
                return nodes
                    .map(nodeToRow)
                    .sort((a, b) => b.priority - a.priority);
            }
        }
        if (sortSetting?.sortingBy === NodeTableColumns.Title) {
            if (sortSetting?.sortOrder === "asc") {
                return nodes.map(nodeToRow).sort((a, b) => {
                    if (a.title < b.title) {
                        return -1;
                    }
                    if (a.title > b.title) {
                        return 1;
                    }
                    return 0;
                });
            }
            if (sortSetting?.sortOrder === "desc") {
                return nodes.map(nodeToRow).sort((b, a) => {
                    if (a.title < b.title) {
                        return -1;
                    }
                    if (a.title > b.title) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
        if (sortSetting?.sortingBy === NodeTableColumns.Links) {
            if (sortSetting?.sortOrder === "asc") {
                return nodes
                    .map(nodeToRow)
                    .sort((a, b) => a.links - b.links);
            }
            if (sortSetting?.sortOrder === "desc") {
                return nodes
                    .map(nodeToRow)
                    .sort((b, a) => a.links - b.links);
            }
        }
        return nodes.map(nodeToRow);
    };

    return (
        <div className="TaskPriorityTableContainer">
            <Table
                borderBetweenRows
                stickyHeader
                size="m"
                verticalAlign="center"
                rows={rows(priorityWeights)}
                columns={columns}
                onCellClick={onCellClick}
                emptyRowsPlaceholder={emptyTable()}
                onSortBy={setSortSetting}
            />
            {menuIsOpen && (
                <ContextMenu
                    isOpen={menuIsOpen}
                    items={items}
                    getItemLabel={(item) => item.label}
                    getItemRightIcon={(item) => item.imageRight}
                    anchorRef={menuRef}
                    onClickOutside={closeMenu}
                />
            )}
            {isAddResFormVisible && selectedNodeId && (
                <ChargeAddForm
                    isModalOpen={isAddResFormVisible}
                    nodeId={selectedNodeId}
                    iterationService={props.iterationService}
                    onClose={() => setAddResFormVisible(false)}
                />
            )}
        </div>
    );
}

type ITaskPriorityTable = {
    taskNodes: Node[];
    iterationService: IIterationService;
    onDeleteNode: (nodeId: string) => void;
    onEditNode: (nodeId: string) => void;
    onChangeTaskVisibility: (nodeId: string) => void;
};

export default TaskPriorityTable;

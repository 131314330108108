import { Charge, NewCharge } from "../../entities/Charge";
import {
    emptyIterationActivity,
    emptyIterationNodeSnapshots,
    emptyIterationResult,
    Iteration,
    IterationActivity,
    IterationItem,
    parseIterationActivity,
    parseIterationNodeSnapshots,
    parseIterationResult,
    startChargeIdValue
} from "../../entities/Iteration";
import { NodeType } from "../../entities/NodeType";
import { PriorityWeight } from "../../entities/PriorityWeight";
import {createObservable, Observable} from "../../utils/Observable";
import {
    IterationAddRequest,
    IterationAddResponse,
    IterationDeleteResponse,
    IterationFinishResponse,
    IterationListResponse,
    IterationExploreParsedResponse,
    IterationUpdateRequest,
} from "../api/iteration/entities/entities";
import IIterationApiProvider from "../api/iteration/provider/IIterationApiProvider";
import ICore from "../core/ICore";
import IStorage from "../storage/IStorage";
import IIterationService from "./IIterationService";

class IterationService implements IIterationService {

    // IIterationService

    chargeList$: Observable<Charge[]>;
    currentIteration$: Observable<Iteration | null>;
    iterationList$: Observable<IterationItem[]>;
    priorityWeightList$: Observable<PriorityWeight[]>;
    
    currentSphereId: number;

    addCharge(newCharge: NewCharge) {
        this._addCharge(newCharge);
    }

    addIteration(
        volume: number,
        onResult: (response: IterationAddResponse) => void
    ) {
        this._addIteration(volume, onResult);
    }

    clean() {
        this._clean();
    }

    deleteCharge(chargeId: string) {
        this._deleteCharge(chargeId);
    }

    deleteIteration(
        iterationId: number,
        onResult: (response: IterationDeleteResponse) => void
    ) {
        this._deleteIteration(iterationId, onResult);
    }

    exploreIteration(
        iterationId: number,
        onResult: (response: IterationExploreParsedResponse) => void
    ) {
        this._exploreIteration(iterationId, onResult);
    }

    getActiveIterationId() {
        return this._getActiveIterationId()
    }

    getCurrentIterationId() {
        return this._getCurrentIterationId()
    }

    getIsCurrentIterationActive() {
        return this._getIsCurrentIterationActive()
    }
    
    fetchActiveIteration(onFinish: VoidFunction) {
        this._fetchActiveIteration(onFinish)
    }
    
    fetchIterationList(
        onResult: (response: IterationListResponse) => void
    ) {
        this._fetchIterationList(onResult);
    }

    finishIteration(
        iterationId: number,
        onResult: (response: IterationFinishResponse) => void
    ) {
        this._finishIteration(iterationId, onResult);
    }

    reloadIterationWeights(onFinish: VoidFunction) {
        this._reloadIterationWeights(onFinish)
    }

    setCurrentIteration(iteration: Iteration | null) {
        this._setCurrentIteration(iteration)
    }

    setSphereId(sphereId: number) {
        this.currentSphereId = sphereId
    }

    // Setup

    private apiProvider: IIterationApiProvider;
    private core: ICore;
    private storage: IStorage;

    constructor(
        apiProvider: IIterationApiProvider,
        core: ICore,
        storage: IStorage
    ) {
        this.apiProvider = apiProvider;
        this.core = core
        this.storage = storage
        this.currentSphereId = -1;

        this.chargeList$ = createObservable<Charge[]>([]);
        this.currentIteration$ = createObservable<Iteration| null>(null);
        this.iterationList$ = createObservable<IterationItem[]>([]);
        this.priorityWeightList$ = createObservable<PriorityWeight[]>([]);
    }

    // Internal

    private _addCharge = (newCharge: NewCharge) => {
        let iteration = this.currentIteration$.get()
        if (!iteration) {
            return
        }
        const charge = this._makeCharge(newCharge)
        if (!charge) {
            return
        }
        iteration.activity.charges.push(charge)
        iteration.activity.lastChargeAutoIncrement = charge.id

        // запоминаем узлы в снепшоте-копии
        if (!iteration.node_snapshots.nodes) {
            iteration.node_snapshots.nodes = []
        }
        const taskNode = this.storage.content.nodes.findLast(node => node.id === charge.node_id)
        if (taskNode) {
            const taskNodeIndex = iteration.node_snapshots.nodes.findIndex(element => element.id === taskNode.id)
            if (taskNodeIndex > 0) {
                iteration.node_snapshots.nodes[taskNodeIndex] = taskNode
            } else {
                iteration.node_snapshots.nodes.push(taskNode)
            }

            for (let link of taskNode.links) {
                const groupId = link.targetNodeId
                const groupNode = this.storage.content.nodes.findLast(node => node.id === groupId)
                if (groupNode) {
                    const groupNodeIndex = iteration.node_snapshots.nodes.findIndex(element => element.id === groupId)
                    if (groupNodeIndex > 0) {
                        iteration.node_snapshots.nodes[groupNodeIndex] = groupNode
                    } else {
                        iteration.node_snapshots.nodes.push(groupNode)
                    }
                }

            }
        }

        this.currentIteration$.set(iteration)
        this.chargeList$.set(iteration.activity.charges)
        this._uploadIteration()
    };

    private _addIteration = (
        volume: number,
        onResult: (response: IterationAddResponse) => void
    ) => {
        const request: IterationAddRequest = {
            sphere_id: this.currentSphereId,
            volume
        }
        this.apiProvider.addIteration(request, (response) => {
            onResult(response);
            if (response.success && this.currentSphereId > 0) {
                this._fetchIterationList(() => {})
            }
        });
    };

    private _clean() {
        this.currentIteration$.set(null)
        this.chargeList$.set([])
        this.priorityWeightList$.set([])
}

    private _deleteCharge = (chargeId: string) => {
        let iteration = this.currentIteration$.get()
        if (!iteration) {
            return
        }
        const charges = iteration.activity.charges.filter(
            charge => charge.id !== chargeId
        )
        iteration.activity.charges = charges
        this.currentIteration$.set(iteration)
        this.chargeList$.set(iteration.activity.charges)
        this._uploadIteration()
    };

    private _deleteIteration = (
        iterationId: number,
        onResult: (response: IterationDeleteResponse) => void
    ) => {
        this.apiProvider.deleteIteration(iterationId, (response) => {
            onResult(response);
            this._clean()
            if (response.success) {
                this.iterationList$.set(
                    this.iterationList$
                        .get()
                        .filter(
                            (iteration) => iteration.iteration_id != iterationId
                        )
                );
            }
        });
    };

    private _exploreIteration = (
        iterationId: number,
        onResult: (response: IterationExploreParsedResponse) => void
    ) => {
        this.apiProvider.exploreIteration(iterationId, (response) => {
            let parsedResponse: IterationExploreParsedResponse = {
                success: response.success,
                error: response.error,
                iteration: undefined
            }
            let iterationDto = response.iteration
            if (iterationDto) {
                let activity = iterationDto.activity ? parseIterationActivity(iterationDto.activity) : emptyIterationActivity()
                let result = iterationDto.result ? parseIterationResult(iterationDto.result) : emptyIterationResult()
                let nodeSnapshots = iterationDto.node_snapshots ? parseIterationNodeSnapshots(iterationDto.node_snapshots) : emptyIterationNodeSnapshots()
                let iteration: Iteration = {
                    iteration_id: iterationDto.iteration_id,
                    is_finished: iterationDto.is_finished,
                    volume: iterationDto.volume,
                    activity: activity,
                    result: result,
                    node_snapshots: nodeSnapshots
                }
                parsedResponse.iteration = iteration
            }
            onResult(parsedResponse);
        });
    };

    private _fetchActiveIteration = (onFinish: VoidFunction) => {
        let activeIterationId = this.getActiveIterationId()
        if (activeIterationId) {
            this.exploreIteration(activeIterationId, response => {
                this._setCurrentIteration(response.iteration || null)
                onFinish()
            })
        } else {
            onFinish()
        }
    }

    private _fetchIterationList = (
        onResult: (response: IterationListResponse) => void
    ) => {
        this.apiProvider.listIteration(this.currentSphereId, (response) => {
            this.iterationList$.set(response.list);
            onResult(response);
        });
    };

    private _finishIteration = (
        iterationId: number,
        onResult: (response: IterationFinishResponse) => void
    ) => {
        this.apiProvider.finishIteration(iterationId, (response) => {
            onResult(response);
            if (response.success && this.currentSphereId > 0) {
                this._fetchIterationList(() => {})
            }
        });
    };
    
    private _generateChargeId = (activity: IterationActivity): string => {
        if (activity.lastChargeAutoIncrement) {
            const newId = (+activity.lastChargeAutoIncrement+1).toString()
            activity.lastChargeAutoIncrement = newId
            return newId
        }
        const len = activity.charges.length;
        if (len < 1) {
            const newId = startChargeIdValue.toString()
            activity.lastChargeAutoIncrement = newId
            return newId;
        }
        // преобразуем в ID в число, ищем максимальное
        const idList: number[] = activity.charges.map((charge) => +charge.id);
        const last: number = idList.sort((a, b) => a - b)[len - 1];
        // инкрементим
        const newId = (last + 1).toString()
        activity.lastChargeAutoIncrement = newId
        return newId;
    };

    private _getActiveIterationId() {
        for (let item of this.iterationList$.get()) {
            if (item.is_finished === false) {
                return item.iteration_id;
            }
        }
        return null;
    }

    private _getCurrentIterationId() {
        return this.currentIteration$.get()?.iteration_id || null
    }

    private _getIsCurrentIterationActive(): boolean {
        const activeId = this.getActiveIterationId()
        const currentId = this.getCurrentIterationId()
        return (activeId && currentId && activeId === currentId) || false
    }

    private _makeCharge = (newCharge: NewCharge) => {
        const activity = this.currentIteration$.get()?.activity
        if (!activity) {
            return null
        }
        return {
            id: this._generateChargeId(activity),
            node_id: newCharge.node_id,
            volume: newCharge.volume
        };
    };

    private _reloadIterationWeights = (onFinish: VoidFunction) => {
        
        const iteration = this.currentIteration$.get()
        if (!iteration) {
            onFinish()
            return
        }
        const snapshotTasks = iteration.node_snapshots.nodes.filter(node => node.type == NodeType.Task)
        this.core.calculateSphere(
            this.storage.content.nodes,
            snapshotTasks,
            iteration?.volume || 0,
            iteration?.activity.charges || [],
            (nodeResults, brokenLinks) => {
                const priorityWeights: PriorityWeight[] = nodeResults.map(result => {
                    const priorityWeight: PriorityWeight = {
                        node_id: result.id,
                        impact: result.impact,
                        iteration_balance: result.iteration_balance
                    }
                    return priorityWeight
                })
                this.priorityWeightList$.set(priorityWeights)
                onFinish()
            }
        );
    }
    
    private _setCurrentIteration = (iteration: Iteration | null) => {
        if (iteration && !iteration.activity) {
            iteration.activity = emptyIterationActivity()
        }
        this.currentIteration$.set(iteration)
        this.chargeList$.set(iteration?.activity.charges || [])
    }
    
    private _uploadIteration = () => {
        const iteration = this.currentIteration$.get()
        if (!iteration) {
            return
        }

        let request: IterationUpdateRequest = {
            iteration_id: iteration.iteration_id,
            volume: iteration.volume,
            is_finished: iteration.is_finished,
            activity: JSON.stringify(iteration.activity),
            result: JSON.stringify(iteration.result),
            node_snapshots: JSON.stringify(iteration.node_snapshots)
        }
        this.apiProvider.updateIteration(request, (_) => {});
        this._reloadIterationWeights(() => {})
    }

}

export default IterationService;

import "./ChargeAddForm.css";

import {useEffect, useRef, useState} from "react";

import {Button} from "@consta/uikit/Button";
import {Modal} from "@consta/uikit/Modal";
import {TextField, TextFieldPropValue} from "@consta/uikit/TextField";

import FormHeader from "../../controls/formHeader/FormHeader";
import IIterationService from "../../../services/iteration/IIterationService";

function ChargeAddForm(props: IChargeAddFormProps) {
    
    const [volume, setVolume] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleVolume = (value: TextFieldPropValue) => {
        const numberValue = (value && +value) || 0
        setVolume(numberValue);
    };

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        inputRef.current?.focus();
    }, [props.isModalOpen]);

    const clean = () => {
        setVolume(0);
    };

    const close = () => {
        props.onClose();
        clean();
    };

    const submit = () => {
        if (volume && volume > 0) {
            const iterationService = props.iterationService
            iterationService.addCharge({
                node_id: props.nodeId,
                volume
            })
            close()
        }
    }

    return (
        <Modal
            isOpen={props.isModalOpen}
            hasOverlay
            position="center"
            onClickOutside={() => close()}
            onEsc={() => {
                close();
            }}
        >
            <div className="ChargeAddForm">
                <FormHeader onClose={close} text="Начисление ресурсов" />
                <div className="ChargeAddForm-content">
                    <TextField
                        inputRef={inputRef}
                        value={volume?.toString()}
                        type="number"
                        placeholder="Укажите объем начисляемых ресурсов"
                        onChange={handleVolume}
                    />
                </div>

                <div className="ChargeAddForm-footer">
                    <Button
                        size="m"
                        view="primary"
                        label="Готово"
                        width="default"
                        onClick={submit}
                        disabled={!(volume) || volume < 0}
                        loading={isLoading}
                    />
                </div>
            </div>
        </Modal>
    );
}

type IChargeAddFormProps = {
    isModalOpen: boolean;
    iterationService: IIterationService;
    nodeId: string;
    onClose: VoidFunction;
};

export default ChargeAddForm;

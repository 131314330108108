import "./SphereNameForm.css";

import {useEffect, useRef, useState} from "react";

import {Button} from "@consta/uikit/Button";
import {Collapse} from "@consta/uikit/Collapse";
import {DragNDropField} from "@consta/uikit/DragNDropField";
import {Modal} from "@consta/uikit/Modal";
import {TextField, TextFieldPropValue} from "@consta/uikit/TextField";
import {Text} from "@consta/uikit/Text";

import IStorage from "../../../services/storage/IStorage";
import {Node} from "../../../entities/Node";
import {NodeType} from "../../../entities/NodeType";
import FormHeader from "../../controls/formHeader/FormHeader";
import { parseSphereContent, SphereContent } from "../../../entities/Sphere";

function SphereNameForm({
    isModalOpen,
    onClose,
    onReady,
    storage,
}: ISphereNameProps) {
    const [title, setTitle] = useState<string>("");
    const handleTitle = (value: TextFieldPropValue) => {
        setTitle(value || "");
    };

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        inputRef.current?.focus();
    }, [isModalOpen]);

    const clean = () => {
        setTitle("");
    };

    const close = () => {
        onClose();
        clean();
    };
    const save = () => {
        storage.setSphereName(title);
        onReady();
        clean();
    };

    const upload = (files: File[]) => {
        let file = files[0];
        let reader = new FileReader();
        reader.onload = (readerEvent: ProgressEvent<FileReader>) => {
            let content = readerEvent.target?.result?.toString();
            if (content) {
                storage.setSphereName(file.name);
                let sphereContent = parseSphereContent(content)
                storage.setSphereContent(sphereContent);

                onReady();
            }
        };
        reader.readAsText(file, "UTF-8");
    };

    return (
        <Modal
            isOpen={isModalOpen}
            hasOverlay
            position="center"
            onClickOutside={() => close()}
            onEsc={() => {
                close();
            }}
        >
            <div className="SphereNameForm">
                <FormHeader onClose={close} text="Выберите сферу" />
                <div className="NewNodeForm-content">
                    <TextField
                        inputRef={inputRef}
                        value={title}
                        type="text"
                        placeholder="Укажите название для новой сферы"
                        onChange={handleTitle}
                    />
                </div>

                <Text className="SphereNameForm-or" view="brand">или</Text>

                <div className="SphereNameForm-upload">
                    <div>
                        <DragNDropField onDropFiles={upload} accept=".afina" />
                    </div>
                </div>

                <div className="NewNodeForm-footer">
                    <Button
                        size="m"
                        view="primary"
                        label="Готово"
                        width="default"
                        onClick={save}
                        disabled={title.length === 0}
                    />
                </div>
            </div>
        </Modal>
    );
}

type ISphereNameProps = {
    isModalOpen: boolean;
    onClose: VoidFunction;
    onReady: VoidFunction;
    storage: IStorage;
};

export default SphereNameForm;

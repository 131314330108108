export type Link = {
    id: string;
    targetNodeId: string;
    linkDescription?: string;
}

export const startLinkIdValue = 1000

export type TempLink = {
    targetNodeId: string;
    linkDescription?: string;
}

import {useRef, useState} from "react";
import "./NodeTable.css";

import {Button} from "@consta/uikit/Button";
import {Switch} from "@consta/uikit/Switch";

import {ContextMenu} from "@consta/uikit/ContextMenu";
import {
    CellClickType,
    SortByProps,
    Table,
    TableColumn,
} from "@consta/uikit/Table";
import {IconComponent} from "@consta/icons/Icon";
import {IconKebab} from "@consta/icons/IconKebab";
import {IconEdit} from "@consta/icons/IconEdit";
import {IconTrash} from "@consta/icons/IconTrash";
import {Text} from "@consta/uikit/Text";

import {NodeType, nodeTypeToString} from "../../../entities/NodeType";
import {
    Node,
    getScaleFactor,
    maxQuality,
    nodeImpactString,
} from "../../../entities/Node";
import EmptySphere from "../emptySphere/EmptySphere";

type NodeRow = {
    id: string;
    weight: string;
    title: string;
    links: number;
    type: string;
    visibility: boolean;
    node: Node;
};

// названия повторяют компоненты строки NodeRow

enum NodeTableColumns {
    Weight = "weight",
    Title = "title",
    Links = "links",
    NodeType = "node",
    Visibility = "visibility",
}

function nodeToRow(node: Node): NodeRow {
    let nodeType = nodeTypeToString(node.type);
    if (node.type === NodeType.Domain && node.quality) {
        let scaleFactor = 1.0 / (node.quality / maxQuality);
        nodeType = nodeType + ` (x${Math.round(scaleFactor * 100) / 100})`;
    }
    return {
        id: node.id,
        weight: nodeImpactString(node.impact),
        title: node.title,
        type: nodeTypeToString(node.type),
        links: node.links.length,
        visibility: true,
        node: node,
    };
}

function makeColumns(
    showType: Boolean,
    onChangeVisibility: (rowId: string) => void,
    getMenuRowId: () => string | undefined,
    getRef: () => React.RefObject<HTMLButtonElement>
): TableColumn<NodeRow>[] {
    let items: TableColumn<NodeRow>[] = [];
    items.push({
        title: "Влия ние",
        accessor: NodeTableColumns.Weight,
        align: "center",
        width: 100,
        sortable: true,
    });
    items.push({
        title: "Название",
        accessor: NodeTableColumns.Title,
        align: "left",
        sortable: true,
    });
    items.push({
        title: "Количество связей",
        accessor: NodeTableColumns.Links,
        align: "center",
        width: 120,
        sortable: true,
    });
    if (showType) {
        items.push({
            title: "Тип",
            accessor: NodeTableColumns.NodeType,
            align: "left",
            width: 160,
            sortable: true,
            renderCell: (row) => {
                let scaleFactor = getScaleFactor(
                    row.node.impact,
                    row.node.quality
                );
                return (
                    <div>
                        <Text>{nodeTypeToString(row.node.type)}</Text>
                        {row.node.type === NodeType.Domain && scaleFactor && (
                            <Text view="brand" size="xs">
                                {`Усиление ${scaleFactor}x`}
                            </Text>
                        )}
                    </div>
                );
            },
        });
    }
    items.push({
        title: "Видимость на сфере",
        accessor: NodeTableColumns.Visibility,
        align: "center",
        sortable: true,
        width: 120,
        renderCell: (row) => {
            return (
                <div className="SwitchContainer" onClick={ event => event.stopPropagation()}>
                    <Switch
                        view="primary"
                        checked={!row.node.is_task_hidden}
                        onChange={(event) => {
                            onChangeVisibility(row.node.id)
                        }}
                    />
                </div>
            );
        },
    });
    items.push({
        title: "",
        accessor: "id",
        align: "center",
        width: 60,
        renderCell: (row) => {
            return (
                <Button
                    ref={row.id === getMenuRowId() ? getRef() : undefined}
                    size="s"
                    view="clear"
                    label={row.id}
                    onlyIcon
                    iconLeft={IconKebab}
                />
            );
        },
    });
    return items;
}

function NodeTable(props: INodeTableProps) {
    const menuRef = useRef(null);
    const [menuRowId, setMenuRowId] = useState<string>("");
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

    const closeMenu = () => {
        setMenuRowId("");
        setMenuIsOpen(false);
    };

    const openMenu = (rowId: string) => {
        setMenuRowId(rowId);
        setMenuIsOpen(true);
    };

    const columns = makeColumns(
        props.showType,
        (nodeId) => {
            props.onChangeTaskVisibility(nodeId)
        },
        () => menuRowId,
        () => menuRef
    );

    const onCellClick = ({
        e,
        type,
        rowId,
        columnIdx,
        ref,
    }: {
        e: React.SyntheticEvent;
        type: CellClickType;
        columnIdx: number;
        ref: React.RefObject<HTMLDivElement>;
        rowId?: string;
    }) => {
        const actionsIndex = columns.length - 1;
        const titleIndex = 1
        if (columnIdx === actionsIndex) {
            rowId && openMenu(rowId);
        }
        if (columnIdx === titleIndex) {
            rowId && props.onEditNode(rowId);
        }
    };

    type MenuItem = {
        label: string;
        imageRight?: IconComponent;
        status?: "primary" | "alert";
        onClick?: React.MouseEventHandler;
    };

    const items: MenuItem[] = [
        {
            label: "Редактировать",
            imageRight: IconEdit,
            onClick: () => {
                props.onEditNode(menuRowId);
                closeMenu();
            },
        },
        {
            label: "Удалить",
            imageRight: IconTrash,
            status: "alert",
            onClick: () => {
                props.onDeleteNode(menuRowId);
                closeMenu();
            },
        },
    ];

    const emptyTable = () => {
        return <EmptySphere />;
    };

    const [sortSetting, setSortSetting] = useState<SortByProps<NodeRow> | null>(
        {sortingBy: NodeTableColumns.Weight, sortOrder : "desc" }
    );

    const rows = () => {
        if (sortSetting?.sortingBy === NodeTableColumns.Weight) {
            if (sortSetting?.sortOrder === "asc") {
                return props.nodes
                    .map(nodeToRow)
                    .sort((a, b) => parseInt(a.weight) - parseInt(b.weight));
            }
            if (sortSetting?.sortOrder === "desc") {
                return props.nodes
                    .map(nodeToRow)
                    .sort((a, b) => parseInt(b.weight) - parseInt(a.weight));
            }
        }
        if (sortSetting?.sortingBy === NodeTableColumns.Title) {
            if (sortSetting?.sortOrder === "asc") {
                return props.nodes.map(nodeToRow).sort((a, b) => {
                    if (a.title < b.title) {
                        return -1;
                    }
                    if (a.title > b.title) {
                        return 1;
                    }
                    return 0;
                });
            }
            if (sortSetting?.sortOrder === "desc") {
                return props.nodes.map(nodeToRow).sort((b, a) => {
                    if (a.title < b.title) {
                        return -1;
                    }
                    if (a.title > b.title) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
        if (sortSetting?.sortingBy === NodeTableColumns.Links) {
            if (sortSetting?.sortOrder === "asc") {
                return props.nodes
                    .map(nodeToRow)
                    .sort((a, b) => a.links - b.links);
            }
            if (sortSetting?.sortOrder === "desc") {
                return props.nodes
                    .map(nodeToRow)
                    .sort((b, a) => a.links - b.links);
            }
        }
        if (sortSetting?.sortingBy === NodeTableColumns.NodeType) {
            if (sortSetting?.sortOrder === "asc") {
                return props.nodes.map(nodeToRow).sort((a, b) => {
                    if (a.type < b.type) {
                        return -1;
                    }
                    if (a.type > b.type) {
                        return 1;
                    }
                    return 0;
                });
            }
            if (sortSetting?.sortOrder === "desc") {
                return props.nodes.map(nodeToRow).sort((b, a) => {
                    if (a.type < b.type) {
                        return -1;
                    }
                    if (a.type > b.type) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
        if (sortSetting?.sortingBy === NodeTableColumns.Visibility) {
            if (sortSetting?.sortOrder === "asc") {
                return props.nodes.map(nodeToRow).sort((a, b) => {
                    if (a.visibility < b.visibility) {
                        return -1;
                    }
                    if (a.visibility > b.visibility) {
                        return 1;
                    }
                    return 0;
                });
            }
            if (sortSetting?.sortOrder === "desc") {
                return props.nodes.map(nodeToRow).sort((b, a) => {
                    if (a.visibility < b.visibility) {
                        return -1;
                    }
                    if (a.visibility > b.visibility) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
        return props.nodes.map(nodeToRow);
    };

    return (
        <div className="NodeTableContainer">
            <Table
                className="NodeTable"
                borderBetweenRows
                stickyHeader
                size="m"
                verticalAlign="center"
                rows={rows()}
                columns={columns}
                onCellClick={onCellClick}
                emptyRowsPlaceholder={emptyTable()}
                onSortBy={setSortSetting}
            />
            {menuIsOpen && (
                <ContextMenu
                    isOpen={menuIsOpen}
                    items={items}
                    getItemLabel={(item) => item.label}
                    getItemRightIcon={(item) => item.imageRight}
                    anchorRef={menuRef}
                    onClickOutside={closeMenu}
                />
            )}
        </div>
    );
}

type INodeTableProps = {
    nodes: Node[];
    onDeleteNode: (nodeId: string) => void;
    onEditNode: (nodeId: string) => void;
    onChangeTaskVisibility: (nodeId: string) => void;
    showType: Boolean;
};

export default NodeTable;

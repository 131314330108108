import "./IterationTable.css";

import {useRef, useState} from "react";

import {Button} from "@consta/uikit/Button";
import {ContextMenu} from "@consta/uikit/ContextMenu";
import {IconComponent} from "@consta/icons/Icon";
import {IconKebab} from "@consta/icons/IconKebab";
import {IconTrash} from "@consta/icons/IconTrash";
import {
    CellClickType,
    SortByProps,
    Table,
    TableColumn,
} from "@consta/uikit/Table";

import TablePlaceholder from "../tablePlaceholder/TablePlaceholder";
import { IterationItem } from "../../../entities/Iteration";
import { useObservable } from "../../../utils/Observable";
import IIterationService from "../../../services/iteration/IIterationService";

type IterationRow = {
    id: string;
    title: string;
};

function itemToRow(item: IterationItem): IterationRow {
    const noSecondsDate = item.create_time.replace(/:\d{2}$/, "");
    let caption = "Итерация от " + noSecondsDate;
    if (!item.is_finished) {
        caption += " (активная)"
    }
    return {
        id: "" + item.iteration_id,
        title: caption,
    };
}

function makeColumns(
    getMenuRowId: () => string | undefined,
    getRef: () => React.RefObject<HTMLButtonElement>
): TableColumn<IterationRow>[] {
    return [
        {
            title: "Итерации",
            accessor: "title",
            align: "left",
            sortable: true,
        },
        {
            title: "",
            accessor: "id",
            align: "center",
            width: 60,
            renderCell: (row) => {
                return (
                    <Button
                        ref={row.id === getMenuRowId() ? getRef() : undefined}
                        size="s"
                        view="clear"
                        label={row.id}
                        onlyIcon
                        iconLeft={IconKebab}
                    />
                );
            },
        },
    ];
}

function IterationTable(props: IIterationTableProps) {

    const menuRef = useRef(null);
    const [menuRowId, setMenuRowId] = useState<string>("");
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

    const iterationList = useObservable(props.iterationService.iterationList$);

    const closeMenu = () => {
        setMenuRowId("");
        setMenuIsOpen(false);
    };

    const openMenu = (rowId: string) => {
        setMenuRowId(rowId);
        setMenuIsOpen(true);
    };

    const columns = makeColumns(
        () => menuRowId,
        () => menuRef
    );

    const onCellClick = ({
        e,
        type,
        rowId,
        columnIdx,
        ref,
    }: {
        e: React.SyntheticEvent;
        type: CellClickType;
        columnIdx: number;
        ref: React.RefObject<HTMLDivElement>;
        rowId?: string;
    }) => {
        const actionsIndex = columns.length - 1;
        if (columnIdx === actionsIndex) {
            rowId && openMenu(rowId);
        } else {
            rowId && props.onSelectIteration(+rowId);
        }
    };

    type MenuItem = {
        label: string;
        imageRight?: IconComponent;
        status?: "primary" | "alert";
        onClick?: React.MouseEventHandler;
    };

    const items: MenuItem[] = [
        {
            label: "Удалить",
            imageRight: IconTrash,
            status: "alert",
            onClick: () => {
                props.onDeleteIteration(+menuRowId);
                closeMenu();
            },
        },
    ];

    const emptyContent = () => {
        return (
            <TablePlaceholder
                isLoading={false}
                placeholderText={
                    "Здесь пока ничего нет. Нажмите плюсик справа, чтобы добавить итерацию."
                }
            />
        );
    };

    const [sortSetting, setSortSetting] =
        useState<SortByProps<IterationRow> | null>({sortingBy: "title", sortOrder : "desc" });

    const rows = () => {
        if (sortSetting?.sortingBy === "title") {
            if (sortSetting?.sortOrder === "asc") {
                return iterationList.map(itemToRow).sort((a, b) => {
                    if (a.title < b.title) {
                        return -1;
                    }
                    if (a.title > b.title) {
                        return 1;
                    }
                    return 0;
                });
            }
            if (sortSetting?.sortOrder === "desc") {
                return iterationList.map(itemToRow).sort((b, a) => {
                    if (a.title < b.title) {
                        return -1;
                    }
                    if (a.title > b.title) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
        return iterationList.map(itemToRow);
    };

    return (
        <div className="IterationTable">
            <Table
                className="IterationTable-table"
                borderBetweenRows
                stickyHeader
                size="m"
                verticalAlign="center"
                rows={rows()}
                columns={columns}
                onCellClick={onCellClick}
                emptyRowsPlaceholder={emptyContent()}
                onSortBy={setSortSetting}
            />
            {menuIsOpen && (
                <ContextMenu
                    isOpen={menuIsOpen}
                    items={items}
                    getItemLabel={(item) => item.label}
                    getItemRightIcon={(item) => item.imageRight}
                    anchorRef={menuRef}
                    onClickOutside={closeMenu}
                />
            )}
        </div>
    );
}


type IIterationTableProps = {
    iterationService: IIterationService;
    onDeleteIteration: (iterationId: number) => void;
    onSelectIteration: (iterationId: number) => void;
};

export default IterationTable;

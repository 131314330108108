import "./LoaderView.css";

import {Loader} from "@consta/uikit/Loader";

function LoaderView(props: ILoaderViewProps) {

    return (
        <div className="LoaderView">
             <Loader size="m" />
        </div>
    );
}

type ILoaderViewProps = {
};

export default LoaderView;

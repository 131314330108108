import "./Sphere.css";

import {useEffect, useRef, useState} from "react";

import {IconAdd} from "@consta/icons/IconAdd";
import {IconList} from "@consta/icons/IconList";
import {IconSave} from "@consta/icons/IconSave";
import {IconShare} from "@consta/icons/IconShare";
import {IconAllDone} from "@consta/icons/IconAllDone";


import {Tabs} from "@consta/uikit/Tabs";

import Page from "../../controls/page/Page";

import {NewNode} from "../../../entities/Node";
import {BrokenLink} from "../../../entities/BrokenLink";
import EditNodeForm from "../../forms/editNodeForm/EditNodeForm";
import IStorage from "../../../services/storage/IStorage";
import ICore from "../../../services/core/ICore";
import IRouter from "../../router/IRouter";
import ISphereService from "../../../services/sphere/ISphereService";
import PageHeader from "../../controls/pageHeader/PageHeader";
import AuthButton from "../../controls/authButton/AuthButton";
import IAuthService from "../../../services/auth/IAuthService";
import BackButton from "../../controls/backButton/BackButton";
import {Route} from "../../router/routes";
import IIterationService from "../../../services/iteration/IIterationService";
import { useObservable } from "../../../utils/Observable";
import Renderer from "../../controls/renderer/Renderer";
import ProjectionRenderer from "../../controls/renderer/ProjectionRenderer";
import { NodeType } from "../../../entities/NodeType";
import CountingView from "../../controls/countingView/CountingView";
import IterationAddForm from "../../forms/iterationAddForm/IterationAddForm";
import HintedButton from "../../controls/hintedButton/HintedButton";
import NodeTable from "../../controls/nodeTable/NodeTable";

enum ExplorerViewType {
    Counting = 1,
    Projection,
    Sphere,
    TaskList,
}

type TabItem = {
    label: string;
    viewType: ExplorerViewType;
};


function SphereScreen(props: ISphereProps) {
    const tabItems: TabItem[] = [];
    tabItems.push({
        label: "[ Сфера ]",
        viewType: ExplorerViewType.Sphere,
    });
    tabItems.push({
        label: "[ Проекция ]",
        viewType: ExplorerViewType.Projection,
    });
    tabItems.push({
        label: "[ Задачи ]",
        viewType: ExplorerViewType.TaskList,
    });
    if (props.authService.isSignedIn()) {
        tabItems.push({
            label: "[ Ресурсы ]",
            viewType: ExplorerViewType.Counting,
        });
    }


    const [tab, setTab] = useState<TabItem>(tabItems[0]);

    const [nodes, setNodes] = useState(props.storage.content.nodes);
    const [_, setNodeResults] = useState(props.storage.nodeResults);
    // TODO: не храню на диске сломанные ссылки, прояснить, зачем вообще надо было хранить
    const [brokenLinks, setBrokenLinks] = useState<BrokenLink[]>([]);
    const addNode = (newNode: NewNode) => {
        if (editingNodeId) {
            props.storage.editNode(editingNodeId, newNode);
        } else {
            props.storage.addNode(newNode);
        }
        setNodes([...props.storage.content.nodes]);
        reloadWeights();
    };

    const deleteNode = (nodeId: string) => {
        props.storage.deleteNode(nodeId);
        setNodes([...props.storage.content.nodes]);
        reloadWeights();
    };

    const editNode = (nodeId: string) => {
        setEditingNodeId(nodeId);
        openNewNodeForm();
        reloadWeights();
    };

    const changeTaskVisibility = (nodeId: string) => {
        props.storage.changeTaskNodeVisibility(nodeId);
        setNodes([...props.storage.content.nodes]);
        reloadWeights();
    };

    const reloadWeights = () => {
        props.core.calculateSphere(
            props.storage.content.nodes, [], 0, [],
            (nodeResults, brokenLinks) => {

                setBrokenLinks(brokenLinks);

                // сохраняем рассчитанные влияния и лейаут на диск
                props.storage.applyNodeResults(nodeResults);

                // если не указать, рассчитываться будет криво (костыль)
                setNodes([...props.storage.content.nodes]);

                // выполняем отрисовку влияний и лейаута
                setNodeResults(props.storage.nodeResults);

                // сохраняем на сервере изменения в исходных узлах
                // TODO: это сайд-эффект, необходимо устранить
                const content = JSON.stringify(props.storage.content);
                props.sphereService.updateSphere(
                    props.storage.sphereId,
                    content,
                    (response) => {
                        //console.log(response);
                    }
                );
            }
        );
    };

    const save = () => {
        props.storage.save();
    };

    const saveAsProjection = () => {
        props.storage.saveAsProjection();
    };

    const [isNewNodeFormOpen, setIsNewNodeFormOpen] = useState<boolean>(false);
    const closeNewNodeForm = () => {
        setIsNewNodeFormOpen(false);
        setEditingNodeId(undefined);
    };
    const openNewNodeForm = (nodeId?: string) => {
        setTargetLinkNodeId(nodeId);
        setIsNewNodeFormOpen(true);
    };

    const hideWithChildren = (initialNodeId: string) => {
        let initialNode = props.storage.getNode(initialNodeId); // по ссылке значение
        if (initialNode) {
            initialNode.hidden = true;

            let childrenIds: string[] = [initialNodeId];
            do {
                let currentId = childrenIds[0];
                for (let node of props.storage.content.nodes) {
                    const isChildOfInitialNode = node.links.find(
                        (link) => link.targetNodeId === currentId
                    );
                    const hasVisibleParent = node.links.find(
                        (link) =>
                            !props.storage.getNode(link.targetNodeId)?.hidden
                    );
                    if (isChildOfInitialNode && !hasVisibleParent) {
                        childrenIds.push(node.id);
                        node.hidden = true;
                    }
                    childrenIds = childrenIds.filter(
                        (item) => item !== currentId
                    );
                }
            } while (childrenIds.length > 0);
        }
    };

    const showWithParents = (initialNodeId: string) => {
        let initialNode = props.storage.getNode(initialNodeId); // по ссылке значение
        if (initialNode) {
            initialNode.hidden = false;

            let parentIds: string[] = [
                ...initialNode.links.map((link) => link.targetNodeId),
            ];
            while (parentIds.length > 0) {
                let currentId = parentIds[0];
                const parentNode = props.storage.getNode(currentId);
                if (parentNode) {
                    parentNode.hidden = false;
                    parentIds = parentIds.filter((id) => id !== currentId);
                    parentIds = [
                        ...parentIds,
                        ...parentNode.links.map((link) => link.targetNodeId),
                    ];
                }
            }
        }
    };

    const onUpdateNode = (initialNodeId: string) => {
        const hidden = props.storage.getNode(initialNodeId)?.hidden;
        if (hidden) {
            showWithParents(initialNodeId);
        } else {
            hideWithChildren(initialNodeId);
        }
        setNodes([...props.storage.content.nodes]);
    };

    const onMoveNodeAfter = (capturedNodeId: string, afterNodeId: string) => {
        props.storage.moveNodeAfter(capturedNodeId, afterNodeId);
        setNodes([...props.storage.content.nodes]);
        reloadWeights();
    };

    const onMoveNodeBefore = (capturedNodeId: string, beforeNodeId: string) => {
        props.storage.moveNodeBefore(capturedNodeId, beforeNodeId);
        setNodes([...props.storage.content.nodes]);
        reloadWeights();
    };

    const [editingNodeId, setEditingNodeId] = useState<string | undefined>();
    const [targetLinkNodeId, setTargetLinkNodeId] = useState<
        string | undefined
    >();

    // однократно при запуске обновляем веса
    // и сохраняем сферу ну сервере
    // TODO: исправить задвоение вызова, не должно зависеть от рендеринга
    useEffect(() => {
        reloadWeights();
    }, []);

    const onTabChange = (value: React.SetStateAction<TabItem>) => {
        setTab(value);
    };

    const currentIteration = useObservable(props.iterationService.currentIteration$);
    const iterationList = useObservable(props.iterationService.iterationList$);

    const shouldShowIterationListButton = () => {
        if (iterationList.length === 0) {
            return false
        }
        if (!currentIteration) {
            return false
        }
        return true
    };
    const shouldShowAddIterationButton = () => {
        if (props.iterationService.getActiveIterationId()) {
            return false
        }
        return !currentIteration
    };

    const shouldShowFinishIterationButton = () => {
        return props.iterationService.getIsCurrentIterationActive()
    };

    const defaultNodeType = (() => {
        const links = targetLinkNodeId
            ? [{targetNodeId: targetLinkNodeId}]
            : [];
        const types = props.core.getAvailableNodeTypes(links);
        return types.length > 0 ? types[0].type : undefined;
    })();

    const makeLeave = () => {
        props.authService.leave();
        props.router.reload();
    };
    const showTariffs = () => {
        props.router.goToRoute(Route.ContentTariffs);
    };

    const [onNewIterationForm, setOnNewIterationForm] = useState<boolean>(false);
    const closeNewIterationForm = () => {
        setOnNewIterationForm(false);
    };
    const openNewIterationForm = () => {
        setOnNewIterationForm(true);
    };

    const getTaskList = () => {
        const currentSphereTasks = nodes.filter((node) => node.type === NodeType.Task)
        return nodes.filter((node) => node.type === NodeType.Task)
    }

    const getLeafGroupList = () => {
        return nodes.filter((node) => node.type === NodeType.Group && node.is_leaf)
    }

    const finishIteration = () => {
        let iterationService = props.iterationService
        let activeIterationId = iterationService.getActiveIterationId()
        if (activeIterationId) {
            iterationService.finishIteration(activeIterationId, () => {
                iterationService.clean()
            })
        }
    }

    const showIterationList = () => {
        let iterationService = props.iterationService
        iterationService.clean()
    }

    return (
        <Page>
            <div className="sphere-container">
                <PageHeader>
                    <BackButton router={props.router} />
                    <AuthButton
                        isAuth={props.authService.isSignedIn()}
                        onLeave={makeLeave}
                        onTariffs={showTariffs}
                    />
                </PageHeader>
                <div className="sphere-header">
                    <Tabs
                        value={tab}
                        onChange={onTabChange}
                        items={tabItems}
                        size="m"
                        getItemAs={() => "div"}
                    />
                    {tab.viewType === ExplorerViewType.Sphere && (
                        <div className="button-container">
                            <HintedButton
                                icon={IconAdd}
                                hint={"Добавить узел"}
                                onClick={() => openNewNodeForm(undefined)}
                            />
                            <HintedButton
                                icon={IconSave}
                                hint={"Сохранить сферу в файл"}
                                onClick={save}
                            />
                        </div>
                    )}
                    {tab.viewType === ExplorerViewType.Projection && (
                        <div className="button-container">
                            <HintedButton
                                icon={IconShare}
                                hint={"Сохранить проекцию в файл"}
                                onClick={saveAsProjection}
                            />
                        </div>
                    )}
                    {tab.viewType === ExplorerViewType.Counting  && (
                            <div className="button-container">
                                {/* finish button */}
                                {shouldShowFinishIterationButton() && (
                                    <HintedButton
                                        icon={IconAllDone}
                                        hint={"Завершить итерацию"}
                                        onClick={finishIteration}
                                    />
                                )}
                                {/* add button */}
                                {shouldShowAddIterationButton() && (
                                    <HintedButton
                                        icon={IconAdd}
                                        hint={"Добавить итерацию"}
                                        onClick={openNewIterationForm}
                                    />
                                )}
                                {/* list button */}
                                {shouldShowIterationListButton() && (
                                    <HintedButton
                                        icon={IconList}
                                        hint={"История итераций"}
                                        onClick={showIterationList}
                                    />
                                )}
                            </div>
                    )}
                </div>
                {tab.viewType === ExplorerViewType.Sphere && (
                    <Renderer
                        nodes={nodes.filter((node) => !node.is_task_hidden)}
                        brokenLinks={brokenLinks}
                        onEditNode={editNode}
                        onAddNode={openNewNodeForm}
                        onMoveNodeAfter={onMoveNodeAfter}
                        onMoveNodeBefore={onMoveNodeBefore}
                    />
                )}
                {tab.viewType === ExplorerViewType.Projection && (
                    <ProjectionRenderer
                        nodes={nodes.filter((node) => !node.is_task_hidden)}
                        onEditNode={editNode}
                        onAddNode={openNewNodeForm}
                        onUpdateNode={onUpdateNode}
                    />
                )}
                {tab.viewType === ExplorerViewType.TaskList && (
                    <NodeTable
                        nodes={getTaskList()}
                        onDeleteNode={deleteNode}
                        onEditNode={editNode}
                        onChangeTaskVisibility={changeTaskVisibility}
                        showType={false}
                    />
                )}
                {tab.viewType === ExplorerViewType.Counting && (
                    <CountingView
                        leafGroupNodes={getLeafGroupList()}
                        taskNodes={getTaskList()}
                        iterationService={props.iterationService}
                        onChangeTaskVisibility={changeTaskVisibility}
                        onDeleteNode={deleteNode}
                        onEditNode={editNode}
                    />
                )}
                {defaultNodeType && (
                    <EditNodeForm
                        isModalOpen={isNewNodeFormOpen}
                        onAddNode={addNode}
                        onDeleteNode={deleteNode}
                        onClose={closeNewNodeForm}
                        storage={props.storage}
                        core={props.core}
                        editingNodeId={editingNodeId}
                        targetLinkNodeId={targetLinkNodeId}
                        defaultType={defaultNodeType}
                    />
                )}
                <IterationAddForm
                    isModalOpen={onNewIterationForm}
                    iterationService={props.iterationService}
                    onClose={closeNewIterationForm}
                />
            </div>
        </Page>
    );
}

interface ISphereProps {
    authService: IAuthService;
    core: ICore;
    storage: IStorage;
    router: IRouter;
    sphereService: ISphereService;
    iterationService: IIterationService;
    isLoading: Boolean;
}

export default SphereScreen;

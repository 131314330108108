import "./BalanceView.css";

import { ProgressLine } from '@consta/uikit/ProgressLine';

import IIterationService from "../../../services/iteration/IIterationService";
import { Text } from "@consta/uikit/Text";
import { useObservable } from "../../../utils/Observable";
import EmptySpace, { VSpace } from "../emptySpace/EmptySpace";
import BalanceTable from "../balanceTable/BalanceTable";
import { Node } from "../../../entities/Node";


function BalanceView(props: IBalanceViewProps) {
    
    const iteration = useObservable(props.iterationService.currentIteration$)
    const priorityWeights = useObservable(props.iterationService.priorityWeightList$)

    const getSpentResAmount = () => {
        if (!iteration) {
            return 0
        }
        const spent = iteration.activity.charges.reduce((accumulator, charge) => {
            return accumulator + charge.volume;
        }, 0);
        return spent
    }

    const getCountedResAmount = () => {
        if (!iteration) {
            return 0
        }
        const volume = iteration.volume
        const counted = priorityWeights.reduce((accumulator, weight) => {
            const maxBalance = volume * ((weight.impact || 0) / 100)
            let balance = weight.iteration_balance || 0
            // не начисляем в итерацию, если превышение лимита.
            // причина: сложно считать максимальную дисперсию (расхождение fillRate может быть больше единицы)
            // TODO: копипаста_1
            if (balance > maxBalance)
            {
                balance = maxBalance
            }
            return accumulator + balance;
        }, 0);
        return counted
    }

    const getIterationProgress = () => {
        if (!iteration) {
            return 0
        }
        const counted = getCountedResAmount()
        return +(counted / iteration.volume * 100).toFixed(2)
    }

    const getEfficiency = () => {
        const spent = getSpentResAmount()
        if (!spent) {
            return null
        }
        return getCountedResAmount() / spent
    }
    const getEfficiencyColor = () => {
        const value = getEfficiency()
        if (value === null) {
            return "brand"
        }
        if (value < 0.5) {
            return "alert"
        }
        if (value < 1) {
            return "warning"
        }
        if (value < 1.5 ) {
            return "brand"
        }
        return "success"
    }

    const getPercentVariance = () => {
        
        // определяем показатели заполненности направлений
        const volume = iteration?.volume
        if (!volume) {
            return 0
        }


        let nodes = props.leafGroupNodes.map(node => {
            let nodeCopy = { ...node }
            for (let weight of priorityWeights) {
                if (nodeCopy.id === weight.node_id) {
                    nodeCopy.iteration_balance = weight.iteration_balance
                    break
                }
            }
            return nodeCopy
        })

        const fillRates: { fillRate: number, impact: number }[] = nodes.map( node => {
            let balance = node.iteration_balance
            if (!balance) {
                return { fillRate: 0, impact: node.impact }
            }
            const maxBalance = volume * (node.impact / 100)
            // TODO: копипаста_1
            if (balance > maxBalance) {
                balance = maxBalance
            }
            return { fillRate: balance / maxBalance, impact: node.impact  }
        })
        const len = fillRates.length
        if (!len) {
            return 0
        }

        // считаем взвешенную дисперсию С УЧЕТОМ ВЛИЯТЕЛЬНОСТИ НАПРАВЛЕНИЯ,
        // т.е. дисбаланс малозначимых направлений должен влиять на общий баланс меньше,
        // чем дисбаланс объемных направлений (в обычной дисперсии их вклад в итоговый баланс одинаков)
        
        // взвешенное среднее
        const weightSum = fillRates.reduce((acc, value) => acc + value.impact, 0)
        const weightedMean = fillRates.reduce((acc, value) => acc + (value.fillRate * value.impact), 0) / weightSum
        
        // дисперсия
        const distances = fillRates.map( value => (((weightedMean - value.fillRate)) ** 2) * value.impact)
        const weightedVariance = distances.reduce((acc, val) => acc + val, 0) / weightSum;

        // считаем максимальную взвешенную дисперсию.
        // максимуму разброса будет, если половина направлений с общим влиянием в 50% будет в нуле,
        // а другая половина с общим влиянием в 50% будет заполнена полностью - максимальный дисбаланс.
        // минимальный баланс - 0.0
        // максимальный баланс - 1.0
        // влиятельность конкретных направлений не влияет на максимальную дисперсию, поскольку влиятельность
        // распределена между половинами поровну, значит, веса в расчете максимальной дисперсии можно 
        // вообще отбросить, и формула становится тривиальной:
        // Dmax = ((a - b) ^ 2) / 4 => 0.25

        const maxWeightedVariance = 0.25

        // const valueMin = 0 // направление не заполнено
        // const valueMax = 1 // направление заполнено полностью

        // // среднее значение влияния
        // const meadWeight = fillRates.reduce((acc, value) => acc + value.impact, 0) / len
        
        // console.log("#weightedMean: ", weightedMean)
        
        // const leftWeightSum = fillRates.reduce((acc, value) => {
        //     let result = acc
        //     if (value.fillRate <= 0.5) {
        //         result = result + value.impact
        //     }
        //     return result
        // }, 0)
        // console.log("# leftWeightSum: ", leftWeightSum)

        // const rightWeightSum = fillRates.reduce((acc, value) => {
        //     let result = acc
        //     if (value.fillRate > 0.5) {
        //         console.log("# left: ", leftWeightSum)
        //         result = result + value.impact
        //     }
        //     return result
        // }, 0);
        // console.log("# rightWeightSum: ", rightWeightSum)
        
        // let weightedMeanMax = (leftWeightSum * valueMin + rightWeightSum * valueMax) / (leftWeightSum + rightWeightSum);
        // console.log("#  maxWeightedVariance: ", weightedMeanMax)

        // const maxWeightedVariance = (leftWeightSum * ((valueMin - weightedMeanMax) ** 2) + rightWeightSum * ((valueMax - weightedMeanMax) ** 2)) /
        //     (leftWeightSum + rightWeightSum)

        // console.log("#   maxWeightedVariance: ", maxWeightedVariance)

        // оцениваем отклонение от среднего
        const result = (1 - weightedVariance / maxWeightedVariance) * 100
        return result
    }

    const getPercentColor = () => {
        const value = getPercentVariance()
        if (value === null) {
            return "brand"
        }
        if (value < 25) {
            return "alert"
        }
        if (value < 75 ) {
            return "warning"
        }
        if (value < 90 ) {
            return "brand"
        }
        return "success"
    }

    const percentVariance = getPercentVariance()
    const efficiency = getEfficiency()

    return (
        <div className="BalanceView">
            <div className="IterationDashboard">
                <div className="StatsContainer">
                    <div className="InfoContainer">
                        { percentVariance !== null  && (
                            <Text view={getPercentColor()} size="m">Баланс: {percentVariance.toFixed(2)}%</Text>
                        )}
                        { efficiency !== null && (
                            <Text view={getEfficiencyColor()} size="m">Эффективность: x{efficiency.toFixed(2)}</Text>
                        )}
                        { (percentVariance !== null || efficiency !== null)  && (
                            <div className="VerticalLine"></div>
                        )}

                        <Text view="primary" size="m">Всего: {iteration?.volume}</Text>
                        <Text view="primary" size="m">Вложено: {getSpentResAmount()}</Text>
                        <Text view="primary" size="m">Начислено: {Math.round(getCountedResAmount()).toFixed(0)}</Text>
                        <div className="ProgressContainer">
                            <ProgressLine size="m" value={getIterationProgress()} />
                            <EmptySpace  space={VSpace.Space8} ></EmptySpace>
                            <Text align= "center" size="m">Общий прогресс итерации: {getIterationProgress()}%</Text>
                        </div>
                    </div>
                </div>
                <div className="StatsContainer">
                    <Text view="brand" size="xl" weight="semibold">Распределение ресурсов по направлениям</Text>
                    <BalanceTable
                        leafGroupNodes={props.leafGroupNodes}
                        iterationService={props.iterationService}
                    />
                </div>
            </div>
        </div>
    )
}

type IBalanceViewProps = {
    iterationService: IIterationService;
    leafGroupNodes: Node[];
}

export default BalanceView;
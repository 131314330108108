import "./ChargeTable.css";

import {useRef, useState} from "react";

import {Button} from "@consta/uikit/Button";
import {ContextMenu} from "@consta/uikit/ContextMenu";
import {IconComponent} from "@consta/icons/Icon";
import {IconKebab} from "@consta/icons/IconKebab";
import {IconTrash} from "@consta/icons/IconTrash";
import {
    CellClickType,
    SortByProps,
    Table,
    TableColumn,
} from "@consta/uikit/Table";

import IIterationService from "../../../services/iteration/IIterationService";
import TablePlaceholder from "../tablePlaceholder/TablePlaceholder";
import { Charge } from "../../../entities/Charge";
import { Node } from "../../../entities/Node";
import { useObservable } from "../../../utils/Observable";

type ChargeRow = {
    id: string;
    task: string;
    title: string;
    volume: number;
};

function itemToRow(item: Charge): ChargeRow {
    let caption = "Начисление №" + item.id.toString()
    return {
        id: "" + item.id,
        task: item.node_id, // далее значение используется при обогащении строки
        title: caption,
        volume: item.volume
    };
}

function makeColumns(
    isActive: boolean,
    getMenuRowId: () => string | undefined,
    getRef: () => React.RefObject<HTMLButtonElement>
): TableColumn<ChargeRow>[] {
    let items: TableColumn<ChargeRow>[] = [];
    items.push({
        title: "Начисление",
        accessor: "title",
        align: "left",
        sortable: true,
    })
    items.push({
        title: "Задача",
        accessor: "task",
        align: "left",
        sortable: true,
    })
    items.push({
        title: "Объём",
        accessor: "volume",
        align: "left",
        sortable: true,
    })
    if (isActive) {
        items.push({
            title: "",
            accessor: "id",
            align: "center",
            width: 60,
            renderCell: (row) => {
                return (
                    <Button
                        ref={row.id === getMenuRowId() ? getRef() : undefined}
                        size="s"
                        view="clear"
                        label={row.id}
                        onlyIcon
                        iconLeft={IconKebab}
                    />
                );
            }
        })
    }
    return items;
}

function ChargeTable(props: IChargeTableProps) {

    const menuRef = useRef(null);
    const [menuRowId, setMenuRowId] = useState<string>("");
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

    const charges = useObservable(props.iterationService.chargeList$)
    
    const currentIteration = useObservable(props.iterationService.currentIteration$);
    const isActive = () => {
        return (currentIteration && !currentIteration.is_finished) || false
    }

    const closeMenu = () => {
        setMenuRowId("");
        setMenuIsOpen(false);
    };

    const openMenu = (rowId: string) => {
        setMenuRowId(rowId);
        setMenuIsOpen(true);
    };

    const columns = makeColumns(
        isActive(),
        () => menuRowId,
        () => menuRef
    );

    const onCellClick = ({
        e,
        type,
        rowId,
        columnIdx,
        ref,
    }: {
        e: React.SyntheticEvent;
        type: CellClickType;
        columnIdx: number;
        ref: React.RefObject<HTMLDivElement>;
        rowId?: string;
    }) => {
        const actionsIndex = columns.length - 1;
        if (columnIdx === actionsIndex) {
            rowId && openMenu(rowId);
        } else {
            // TODO: пока ничего не делаем
            //rowId && props.onSelectCharge(rowId);
        }
    };

    type MenuItem = {
        label: string;
        imageRight?: IconComponent;
        status?: "primary" | "alert";
        onClick?: React.MouseEventHandler;
    };

    const items: MenuItem[] = [
        {
            label: "Удалить",
            imageRight: IconTrash,
            status: "alert",
            onClick: () => {
                props.iterationService.deleteCharge(menuRowId);
                closeMenu();
            },
        },
    ];

    const emptyContent = () => {
        return (
            <TablePlaceholder
                isLoading={false}
                placeholderText={
                    "Здесь пока ничего нет. Перейдите на вкладку «Очередь задач», чтобы добавить начисления."
                }
            />
        );
    };

    const [sortSetting, setSortSetting] =
        useState<SortByProps<ChargeRow> | null>({sortingBy: "title", sortOrder : "desc" });

    const rows = () => {
        if (sortSetting?.sortingBy === "title") {
            if (sortSetting?.sortOrder === "asc") {
                return charges.map(itemToRow).sort((a, b) => {
                    if (a.title < b.title) {
                        return -1;
                    }
                    if (a.title > b.title) {
                        return 1;
                    }
                    return 0;
                });
            }
            if (sortSetting?.sortOrder === "desc") {
                return charges.map(itemToRow).sort((b, a) => {
                    if (a.title < b.title) {
                        return -1;
                    }
                    if (a.title > b.title) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
        if (sortSetting?.sortingBy === "task") {
            if (sortSetting?.sortOrder === "asc") {
                return charges.map(itemToRow).sort((a, b) => {
                    if (a.task < b.task) {
                        return -1;
                    }
                    if (a.task > b.task) {
                        return 1;
                    }
                    return 0;
                });
            }
            if (sortSetting?.sortOrder === "desc") {
                return charges.map(itemToRow).sort((b, a) => {
                    if (a.task < b.task) {
                        return -1;
                    }
                    if (a.task > b.task) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
        return charges.map(itemToRow);
    };

    const enrichedRows = () => {
        let rowsList: ChargeRow[] = []
        rows().map(row => {
            for (let node of props.taskNodes) {
                if (node.id === row.task) {
                    row.task = node.title
                    rowsList.push(row)
                }
            }
        })
        return rowsList;
    }

    return (
        <div className="ChargeTable">
            <Table
                borderBetweenRows
                stickyHeader
                size="m"
                verticalAlign="center"
                rows={enrichedRows()}
                columns={columns}
                onCellClick={onCellClick}
                emptyRowsPlaceholder={emptyContent()}
                onSortBy={setSortSetting}
            />
            {menuIsOpen && (
                <ContextMenu
                    isOpen={menuIsOpen}
                    items={items}
                    getItemLabel={(item) => item.label}
                    getItemRightIcon={(item) => item.imageRight}
                    anchorRef={menuRef}
                    onClickOutside={closeMenu}
                />
            )}
        </div>
    );
}


type IChargeTableProps = {
    iterationService: IIterationService;
    taskNodes: Node[];
};

export default ChargeTable;

import {useEffect, useState} from "react";
import "./BalanceTable.css";

import {Text} from "@consta/uikit/Text";
import { ProgressLine } from '@consta/uikit/ProgressLine';

import {
    SortByProps,
    Table,
    TableColumn,
} from "@consta/uikit/Table";

import { Node } from "../../../entities/Node";
import IIterationService from "../../../services/iteration/IIterationService";
import { useObservable } from "../../../utils/Observable";

type NodeRow = {
    id: string;
    title: string;
    balance: number;
    balance_real_rounded: number;
    maxBalance: number;
    fillRate: number;
    balance_planned: number;
};

enum NodeTableColumns {
    BalancePlanned = "balance_planned",
    Balance = "balance",
    BalanceReal = "balance_real_rounded",
    Title = "title",
}

function makeColumns(iterationVolume: number): TableColumn<NodeRow>[] {
    let items: TableColumn<NodeRow>[] = [];
        
    items.push({
        title: "Наполняемость направления",
        accessor: NodeTableColumns.Title,
        align: "left",
        sortable: true,
        renderCell: (row) => {
            return (
                <div className="LineContainer" onClick={ event => event.stopPropagation()}>
                    <div className="LineInfo">
                        <Text>{ `${row.title} (${row.balance} из ${row.maxBalance.toFixed(0)})`}</Text>
                        <Text>{ `${(row.fillRate * 100).toFixed(0)}%`}</Text>
                    </div>
                    <ProgressLine size="m" value={row.fillRate * 100} />
                </div>
            );
        },

    });

    items.push({
        title: "План",
        accessor: NodeTableColumns.BalancePlanned,
        align: "center",
        width: 140,
        sortable: true,
    });

    items.push({
        title: "Вложено",
        accessor: NodeTableColumns.Balance,
        align: "center",
        width: 140,
        sortable: true,
    });

    items.push({
        title: "Начислено",
        accessor: NodeTableColumns.BalanceReal,
        align: "center",
        width: 140,
        sortable: true,
    });

    return items;
}

function BalanceTable(props: IBalanceTable) {

    const currentIteration = useObservable(props.iterationService.currentIteration$);
    
    const priorityWeights = useObservable(props.iterationService.priorityWeightList$)

    const columns = makeColumns(currentIteration?.volume || 0);

    const emptyTable = () => {
        return (
            <Text className="EmptyTable" view="secondary" size="m">
                Здесь пока ничего нет. Добавьте направления в сферу, чтобы начать.
            </Text>
        )
    };

    const [sortSetting, setSortSetting] =
        useState<SortByProps<NodeRow> | null>({sortingBy: NodeTableColumns.Balance, sortOrder : "asc" });

    const [rows, setRows] = useState<NodeRow[]>([])

    useEffect(() => {

        let nodes = props.leafGroupNodes.map(node => {
            let nodeCopy = { ...node }
            for (let weight of priorityWeights) {
                if (nodeCopy.id === weight.node_id) {
                    nodeCopy.iteration_balance = weight.iteration_balance
                    break
                }
            }
            return nodeCopy
        })

        const volume = currentIteration?.volume || 0
        const result = nodes.map(node => {
            const id = node.id
            const title = node.title
            let balance = node.iteration_balance || 0
            const maxBalance = volume * (node.impact / 100)
            // TODO: копипаста_1
            let balance_real = balance
            if (balance_real > maxBalance) {
                balance_real = maxBalance
            }
            const fillRate = maxBalance ? balance_real / maxBalance : 0
            const balance_real_rounded = Math.round(balance_real)
            const balance_planned =  Math.round(maxBalance)
            return { id, title, balance, balance_real_rounded, maxBalance, fillRate, balance_planned }
        })
        setRows(result)
    }, [currentIteration?.volume, priorityWeights])

    const makeRows = () => {
        
        if (sortSetting?.sortingBy === NodeTableColumns.Balance) {
            if (sortSetting?.sortOrder === "asc") {
                return rows
                    .sort((a, b) => a.balance - b.balance);
            }
            if (sortSetting?.sortOrder === "desc") {
                return rows
                    .sort((a, b) => b.balance - a.balance);
            }
        }
        if (sortSetting?.sortingBy === NodeTableColumns.Title) {
            if (sortSetting?.sortOrder === "asc") {
                return rows
                    .sort((a, b) => a.fillRate - b.fillRate);
            }
            if (sortSetting?.sortOrder === "desc") {
                return rows
                    .sort((a, b) => b.fillRate - a.fillRate);
            }
        }
        if (sortSetting?.sortingBy === NodeTableColumns.BalancePlanned) {
            if (sortSetting?.sortOrder === "asc") {
                return rows
                    .sort((a, b) => a.balance_planned - b.balance_planned);
            }
            if (sortSetting?.sortOrder === "desc") {
                return rows
                    .sort((a, b) => b.balance_planned - a.balance_planned);
            }
        }
        return rows;
    };

    return (
        <div className="BalanceTableContainer">
            <Table
                borderBetweenRows
                stickyHeader
                size="m"
                verticalAlign="center"
                rows={makeRows()}
                columns={columns}
                emptyRowsPlaceholder={emptyTable()}
                onSortBy={setSortSetting}
            />
        </div>
    );
}

type IBalanceTable = {
    leafGroupNodes: Node[];
    iterationService: IIterationService;
};

export default BalanceTable;

import IStorage from "./IStorage";

import {NewNode, Node, cloneNodes, deleteNode} from "../../entities/Node";
import {Link, startLinkIdValue, TempLink} from "../../entities/Link";
import {NodeType} from "../../entities/NodeType";
import {NodeResult} from "../api/sphere/sphere";
import { Iteration } from "../../entities/Iteration";
import { parseSphereContent, SphereContent, startNodeIdValue } from "../../entities/Sphere";


class Storage implements IStorage {
    // ISphereStorage

    content: SphereContent;
    nodeResults: NodeResult[];
    iterations: Iteration[];

    addNode(newNode: NewNode) {
        this._addNode(newNode);
    }

    applyNodeResults(result: NodeResult[]) {
        this._applyNodeResults(result);
    }

    deleteNode(nodeId: string) {
        this._deleteNode(nodeId);
    }

    editNode(editingNodeId: string, node: NewNode) {
        this._editNode(editingNodeId, node);
    }

    changeTaskNodeVisibility(nodeId: string) {
        this._changeTaskNodeVisibility(nodeId);
    }

    save() {
        this._save(this.content, "");
    }
    
    saveAsProjection() {
        this._saveAsProjection();
    }

    setSphereId(sphereId: number) {
        this._setSphereId(sphereId)
    }

    getSphereId() {
        return this._getSphereId()
    }

    setSphereName(name: string) {
        this._setSphereName(name);
    }

    getSphereName() {
        return this._getSphereName()
    }

    setSphereContent(content: SphereContent) {
        this._setSphereContent(content);
    }

    setSphereIterations(iterations: Iteration[]) {
        this._setSphereIterations(iterations);
    }

    getNode(nodeId: string) {
        return this._getNode(nodeId)
    }

    moveNodeAfter(capturedNodeId: string, afterNodeId: string) {
        return this._moveNodeAfter(capturedNodeId, afterNodeId)
    }

    moveNodeBefore(capturedNodeId: string, beforeNodeId: string) {
        return this._moveNodeBefore(capturedNodeId, beforeNodeId)
    }

    getAccessToken() {
        return this._getAccessToken()
    }
    
    setAccessToken(token: string | undefined) {
        return this._setAccessToken(token)
    }

    getRefreshToken() {
        return this._getRefreshToken()
    }

    setRefreshToken(token: string | undefined) {
        return this._setRefreshToken(token)
    }

    isSignedIn() {
        return this._isSignedIn()
    }


    // Setup

    sphereId: number

    constructor() {
        this.content = this.retrieveSphereContent();
        this.nodeResults = [];
        this.sphereId = -1
        this.iterations = [];
    }

    // Internal

    private storageNodesId = "sphere_key";

    private _addNode = (newNode: NewNode) => {
        // добавляем узел
        const node = this.makeNode(newNode);
        const result = [...this.content.nodes, node];
        this.updateNodes(result);
        // // обновляем ссылки (прописываем в домены ссылку на ключ)
        // if (node.type === NodeType.Key) {
        //     const result = [...this.nodes];
        //     for (let index in result) {
        //         if (result[index].type === NodeType.Domain) {
        //             result[index].links = [
        //                 this.makeLink({targetNodeId: node.id}),
        //             ];
        //         }
        //     }
        // }
    }

    private _deleteNode = (nodeId: string) => {
        const result = deleteNode(nodeId, this.content.nodes)
        this.updateNodes(result);
    }

    private makeNode = (newNode: NewNode) => {
        return {
            id: this.generateNodeId(this.content),
            title: newNode.title,
            type: newNode.type,
            quality: newNode.quality,
            level: 0,
            draw_level: 0,
            is_leaf: false,
            is_cycled: false,
            complex_quality_factor: 0,
            iteration_balance: 0,
            impact: 0,
            links: newNode.links.map(this.makeLink),
            nodeDescription: newNode.nodeDescription,
            qualityDescription: newNode.qualityDescription
        };
    };

    private makeLink = (tempLink: TempLink) => {
        return {
            id: this.generateLinkId(this.content),
            targetNodeId: tempLink.targetNodeId,
            linkDescription: tempLink.linkDescription
        };
    };

    private _applyNodeResults = (calculatedLevels: NodeResult[]) => {
        const result = this.content.nodes.map((node) => {
            let updated = {...node};
            for (let calculated of calculatedLevels) {
                if (calculated.id === node.id) {
                    updated.draw_level = calculated.draw_level;
                    updated.is_leaf = calculated.is_leaf;
                    updated.is_cycled = calculated.is_cycled;
                    updated.impact = calculated.impact;
                    updated.complex_quality_factor = calculated.complex_quality_factor;
                    updated.iteration_balance = calculated.iteration_balance;
                }
            }
            return updated;
        });
        this.updateNodes(result);

    };

    private updateNodes = (newVersion: Node[]) => {
        this.content.nodes = newVersion;
        this.saveNodes(newVersion);
    };

    private saveNodes = (nodes: Node[]) => {
        const rawData = JSON.stringify({nodes: nodes});
        localStorage.setItem(this.storageNodesId, rawData);
    };

    private retrieveSphereContent = (): SphereContent => {
        const userData = localStorage.getItem(this.storageNodesId);
        return parseSphereContent(userData)
    };

    private generateNodeId = (content: SphereContent): string => {
        if (content.lastNodeAutoIncrement) {
            const newId = (+content.lastNodeAutoIncrement+1).toString()
            content.lastNodeAutoIncrement = newId
            return newId
        }
        const len = content.nodes.length;
        if (len < 1) {
            const newId = startNodeIdValue.toString()
            content.lastNodeAutoIncrement = newId
            return newId;
        }
        // преобразуем в ID в число, ищем максимальное
        const idList: number[] = content.nodes.map((node) => +node.id);
        const last: number = idList.sort((a, b) => a - b)[len - 1];
        // инкрементим
        const newId = (last + 1).toString()
        content.lastNodeAutoIncrement = newId
        return newId;
    };

    private generateLinkId = (content: SphereContent): string => {
        if (content.lastLinkAutoIncrement) {
            const newId = (+content.lastLinkAutoIncrement+1).toString()
            content.lastLinkAutoIncrement = newId
            return newId
        }
        var allLinks: Link[] = [];
        for (let node of content.nodes) {
            allLinks = [...allLinks, ...node.links];
        }
        const len = allLinks.length;
        if (len < 1) {
            const newLinkId = startLinkIdValue.toString()
            content.lastLinkAutoIncrement = newLinkId
            return newLinkId;
        }
        const idList: number[] = allLinks.map((link) => +link.id);
        const last: number = idList.sort((a, b) => a - b)[len - 1];
        const newLinkId = (last + 1).toString()
        content.lastLinkAutoIncrement = newLinkId
        return newLinkId;
    };

    private _editNode = (editingNodeId: string, node: NewNode) => {
        let result = this.content.nodes; // копию не делаем
        for (let index in result) {
            if (result[index].id === editingNodeId) {
                result[index].links = node.links.map(this.makeLink);
                result[index].quality = node.quality;
                result[index].title = node.title;
                result[index].type = node.type;
                result[index].qualityDescription = node.qualityDescription;
                result[index].nodeDescription = node.nodeDescription;
            }
        }
        this.content.nodes = result;
        this.saveNodes(result);
    };

    private _changeTaskNodeVisibility = (nodeId: string) => {
        let result = this.content.nodes; // копию не делаем
        for (let index in result) {
            if (result[index].id === nodeId) {
                result[index].is_task_hidden = !result[index].is_task_hidden
            }
        }
        this.content.nodes = result;
        this.saveNodes(result);
    }

    private _save = (content: SphereContent, postfix: string) => {
        const blob = new Blob([JSON.stringify(content)], {
            type: "text/json",
        });
        const link = document.createElement("a");

        const extension = ".afina";
        const name = this.storageNodesId
        if (name.endsWith(extension)) {
            link.download = name.replace(extension, postfix + extension);
        } else {
            link.download = name + postfix + extension;
        }
        link.href = window.URL.createObjectURL(blob);
        link.dataset.downloadurl = ["text/json", link.download, link.href].join(
            ":"
        );

        const evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
        });

        link.dispatchEvent(evt);
        link.remove();
    };

    private _saveAsProjection = () => {
        let nodesCopy = cloneNodes(this.content.nodes)
        for (let node of nodesCopy) {
            if (node.hidden) {
                node.hidden = undefined
                node.title = ""
                node.invisible = true
            }
        }
        let content = this.content
        content.nodes = nodesCopy
        this._save(content, "_projection");
    }

    private _setSphereName = (name: string) => {
        this.storageNodesId = name;
        this.content = this.retrieveSphereContent();

        if (this.content.nodes.length === 0) {
            this._addNode({
                title: name,
                type: NodeType.Key,
                links: [],
            });
        }
    };

    private _setSphereContent = (content: SphereContent) => {
        this.content = content;
    };

    private _setSphereIterations = (iterations: Iteration[]) => {
        this.iterations = iterations;
    };

    private _getNode = (nodeId: string) => {
        for(let node of this.content.nodes) {
            if (node.id === nodeId) {
                return node
            }
        }
        return undefined
    }

    private _moveNodeAfter = (capturedNodeId: string, afterNodeId: string) => {

        const afterNodeIndex = this.content.nodes.findIndex(element => element.id === afterNodeId)
        const movingElement = this.content.nodes.find(element => element.id === capturedNodeId)
        if (afterNodeIndex && movingElement) {
            this.content.nodes = this.content.nodes.filter(element => element.id !== capturedNodeId)
            this.content.nodes.splice(afterNodeIndex + 1, 0, movingElement);
            this.saveNodes(this.content.nodes);
        }
    }

    private _moveNodeBefore = (capturedNodeId: string, beforeNodeId: string) => {

        const beforeNodeIndex = this.content.nodes.findIndex(element => element.id === beforeNodeId)
        const movingElement = this.content.nodes.find(element => element.id === capturedNodeId)
        if (beforeNodeIndex && movingElement) {
            this.content.nodes = this.content.nodes.filter(element => element.id !== capturedNodeId)
            this.content.nodes.splice(beforeNodeIndex, 0, movingElement);
            this.saveNodes(this.content.nodes);
        }
    }
    private _accessTokenKey = "accessTokenKey"

    private _getAccessToken = () => {
        return localStorage.getItem(this._accessTokenKey) || undefined
    }

    private _setAccessToken = (token: string | undefined) => {
        if (token) {
            localStorage.setItem(this._accessTokenKey, token);
        } else {
            localStorage.removeItem(this._accessTokenKey)
        }
    }

    private _refreshTokenKey = "refreshTokenKey"

    private _getRefreshToken = () => {
        return localStorage.getItem(this._refreshTokenKey) || undefined;
    }

    private _setRefreshToken = (token: string | undefined) => {
        if (token) {
            localStorage.setItem(this._refreshTokenKey, token);
        } else {
            localStorage.removeItem(this._refreshTokenKey)
        }
    }

    private _isSignedIn = () => {
        return this._getAccessToken() != undefined
    }

    private _getSphereName = () => {
        return this.storageNodesId // присвоение в _setSphereName(name)
    }

    private _getSphereId = () => {
        return this.sphereId
    }

    private _setSphereId = (sphereId: number) => {
        this.sphereId = sphereId
    }

}

export default Storage;

export type Charge = {
    id: string;
    node_id: string;
    volume: number;
}

export type NewCharge = {
    node_id: string;
    volume: number;
}

export type ApiCharge = {
    node_id: string;
    volume: number;
}

export function chargeToApi(charge: Charge): ApiCharge {
    return {
        node_id: charge.node_id,
        volume: charge.volume,
    };
}
